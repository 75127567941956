import { createSlice } from "@reduxjs/toolkit";

export interface autoUpdateStateType {
  isOpen: boolean;
}

const initialState: autoUpdateStateType = {
  isOpen: false,
};

export const autoUpdateSlice = createSlice({
  name: "auto-update-slice",
  initialState,
  reducers: {
    openAutoUpdate: (state) => {
      state.isOpen = true;
    },
    closeAutoUpdate: (state) => {
      state.isOpen = false;
    },
    toggleAutoUpdate: (state) => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { openAutoUpdate, closeAutoUpdate, toggleAutoUpdate } =
  autoUpdateSlice.actions;
export default autoUpdateSlice.reducer;
