/**
 * This global state holds the ID number of a component which is selected
 * currently.
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface componentState {
  id?: number;
}

const initialState: componentState = {
  id: undefined,
};

const componentSlice = createSlice({
  name: "component-slice",
  initialState: initialState,
  reducers: {
    setComponentID: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },
  },
});

export const { setComponentID } = componentSlice.actions;
export default componentSlice.reducer;
