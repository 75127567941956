import ComponentTreeSelect from "../../components/ComponentTreeSelect";
import { PageHeader } from "../../components/PageHeader";
import CalendarHeatmapCard from "./CalendarHeatmapCard";
import DynamicHeatmapCard from "./DynamicHeatmapCard";

export const Statistics = () => {
  return (
    <div>
      <PageHeader title="Statistics" element={<ComponentTreeSelect />} />
      <div className="flex gap-2 flex-col lg:flex-row">
        <CalendarHeatmapCard />
        <DynamicHeatmapCard />
      </div>
    </div>
  );
};
