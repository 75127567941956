import { baseClient } from "./axiosConfig";
import {
  DeviceNextMetersAPIResponse,
  GroupListAPIResponse,
  GroupGetChildAPIResponse,
  DeviceListWithPropsAPIResponse,
  DeviceListWithoutPropsAPIResponse,
  GetValueAPIResponse,
  ComponentProperties,
  DeviceConnectionStatusAPIResponse,
} from "../types/ldmResponseTypes";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";

export async function getGroupList() {
  try {
    const response: AxiosResponse<GroupListAPIResponse> = await baseClient.get(
      process.env.REACT_APP_URL_DEVICE_GROUP_LIST!
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getGroupChilds(groupID: number) {
  try {
    const response: AxiosResponse<GroupGetChildAPIResponse> =
      await baseClient.post(process.env.REACT_APP_URL_DEVICE_GROUP_GET_CHILD!, {
        component_id: groupID,
      });
    if (response.status !== 200) return [];
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function createGroup(name: string, parentID?: number) {
  try {
    await baseClient.post(process.env.REACT_APP_URL_DEVICE_GROUP_CREATE!, {
      name: name,
      parent_id: parentID,
    });
    toast.success("Group has been created successfully!");
  } catch (error) {
    console.error(error);
    toast.error("A problem has been occured while creating a group.");
    throw error;
  }
}

export async function getDeviceList(withProperties?: String[]) {
  try {
    let response: AxiosResponse<
      DeviceListWithoutPropsAPIResponse | DeviceListWithPropsAPIResponse
    >;
    if (withProperties === undefined)
      response = await baseClient.post(process.env.REACT_APP_URL_DEVICE_LIST!);
    else
      response = await baseClient.post(process.env.REACT_APP_URL_DEVICE_LIST!, {
        with_properties: withProperties,
      });
    if (response.status !== 200) return [];
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getDeviceNextMeters(componentID: number) {
  try {
    const response: AxiosResponse<DeviceNextMetersAPIResponse> =
      await baseClient.post(process.env.REACT_APP_URL_DEVICE_NEXT_METERS!, {
        component_id: componentID,
      });
    if (response.status !== 200) return [];
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

/**
 * @returns properties of the given component. If you do not provide a propertyNames parameter
 * then it returns all properties.
 */
export async function getValue(componentId: number, propertyNames?: string[]) {
  const response: AxiosResponse<GetValueAPIResponse> = await baseClient.post(
    process.env.REACT_APP_URL_DEVICE_GET_VALUE!,
    {
      component_id: componentId,
      ...(propertyNames && { properties: propertyNames }),
    }
  );
  return response;
}

export async function setValue(
  componentId: number,
  properties: ComponentProperties,
  force?: boolean
) {
  return await baseClient.post(process.env.REACT_APP_URL_DEVICE_SET_VALUE!, {
    component_id: componentId,
    ...properties,
    ...(force && { force: force }),
  });
}

export async function configurationLog(componentId: number) {
  try {
    const response: AxiosResponse = await baseClient.post(
      process.env.REACT_APP_URL_DEVICE_CONFIGURATION_LOG!,
      { component_id: componentId }
    );
    if (response.status !== 200) return [];
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function deviceConnectionStatus(componentIds: number[]) {
  try {
    const response: AxiosResponse<DeviceConnectionStatusAPIResponse> =
      await baseClient.post(
        process.env.REACT_APP_URL_DEVICE_CONNECTION_STATUS!,
        { component_ids: componentIds }
      );
    if (response.status !== 200) return [];
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function createDevice(
  device_type: string,
  component_id: number,
  group_id: number,
  pre_meter_id?: number
) {
  try {
    if (device_type === "T1") {
      await baseClient.post(process.env.REACT_APP_URL_DEVICE_CREATE!, {
        device_type: device_type,
        component_id: component_id,
        group_id: group_id,
      });
    } else {
      await baseClient.post(process.env.REACT_APP_URL_DEVICE_CREATE!, {
        device_type: device_type,
        component_id: component_id,
        group_id: group_id,
        pre_meter_id: pre_meter_id,
      });
    }
    toast.success("Device has been created successfully!");
  } catch (error) {
    console.error(error);
    toast.error("A problem has been occured while creating a Device.");
    throw error;
  }
}
