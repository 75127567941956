import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useMemo, useState } from "react";
import { getComponentsList } from "../../utils/componentUtils";
import { isEmailValid } from "../../utils/otherUtils";
import { toast } from "react-toastify";
import { baseClient } from "../../services/axiosConfig";

const URL_USER_CREATE = process.env.REACT_APP_URL_USER_CREATE!;
const DEFAULT_ZONE = process.env.REACT_APP_DEFAULT_USER_TIMEZONE!;
const DEFAULT_LANG = process.env.REACT_APP_DEFAULT_USER_LANGUAGE!;

interface Props {
  userGroups: any;
}

const CreateUserForm = (props: Props) => {
  const { userGroups } = props;
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirm_password: "",
    language: DEFAULT_LANG,
    zone: DEFAULT_ZONE,
    group: "",
    meter_groups: [],
    meter_ids: [],
  });

  const selectOptions: { [key: string]: Array<{ value: any; label: any }> } =
    useMemo(() => {
      const components = getComponentsList();
      return {
        language: [
          { value: "en", label: "English" },
          { value: "tr", label: "Turkish" },
        ],
        // @ts-ignore
        zone: Intl.supportedValuesOf("timeZone").map((z) => ({
          value: z,
          label: z,
        })),
        group: userGroups.map((g: any) => ({
          value: g.group_name as string,
          label: g.group_name as string,
        })),
        meter_groups: components
          .filter((c) => c.type === "group")
          .map((c) => ({ value: c.id, label: c.name ?? c.id })),
        meter_ids: components
          .filter((c) => c.type !== "group")
          .map((c) => ({ value: c.id, label: c.name ?? c.id })),
      };
    }, [userGroups]);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (formData.confirm_password !== formData.password)
      return toast.error("Passwords do not match!");
    if (!isEmailValid(formData.email)) return toast.error("Invalid email!");
    try {
      await baseClient.post(URL_USER_CREATE, formData);
      toast.success("User has been created successfully!");
    } catch (error) {
      toast.error("A problem has been occured while creating user.");
    }
  }

  function editLabel(label: string) {
    return (label[0].toUpperCase() + label.slice(1)).replaceAll("_", " ");
  }

  return (
    <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-6 gap-x-3">
      {Object.entries(formData).map(([key, value]) => {
        switch (key) {
          case "email":
            return (
              <TextField
                key={key}
                label={editLabel(key)}
                type="email"
                required
                defaultValue={value}
                className="col-span-2"
                size="small"
                onChange={(e) =>
                  setFormData({ ...formData, [key]: e.target.value })
                }
              />
            );
          case "firstname":
          case "lastname":
            return (
              <TextField
                key={key}
                required
                label={editLabel(key)}
                type="text"
                defaultValue={value}
                className="col-span-1"
                size="small"
                onChange={(e) =>
                  setFormData({ ...formData, [key]: e.target.value })
                }
              />
            );
          case "password":
            return (
              <TextField
                key={key}
                label={editLabel(key)}
                type="password"
                required
                value={value}
                className="col-span-1"
                size="small"
                onChange={(e) =>
                  setFormData({ ...formData, [key]: e.target.value })
                }
                InputProps={{ autoComplete: "new-password" }}
              />
            );
          case "confirm_password":
            return (
              <TextField
                key={key}
                label={editLabel(key)}
                type="password"
                required
                value={value}
                className="col-span-1"
                size="small"
                onChange={(e) =>
                  setFormData({ ...formData, [key]: e.target.value })
                }
                InputProps={{ autoComplete: "new-password" }}
              />
            );
          case "language":
          case "zone":
          case "group":
          case "meter_groups":
          case "meter_ids":
            return (
              <FormControl
                key={key}
                size="small"
                required={!["meter_groups", "meter_ids"].includes(key)}
                className={
                  ["language", "zone"].includes(key)
                    ? "col-span-1"
                    : "col-span-2"
                }
              >
                <InputLabel>{editLabel(key)}</InputLabel>
                <Select
                  multiple={["meter_groups", "meter_ids"].includes(key)}
                  value={value as any}
                  label={key}
                  sx={{ bgcolor: "white" }}
                  size="small"
                  onChange={(e) =>
                    setFormData({ ...formData, [key]: e.target.value })
                  }
                >
                  {selectOptions[key].map((o) => (
                    <MenuItem key={o.value} value={o.value}>
                      {o.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          default:
            return null;
        }
      })}
      <Button
        variant="contained"
        type="submit"
        className="col-span-2"
        children="Submit"
      />
    </form>
  );
};

export default CreateUserForm;
