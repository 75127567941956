import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IconButton } from "@mui/material";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  setTimeRange,
  setTimeRangeOption,
} from "../../features/timeRangeSlice";
import { RootState } from "../../store";

type TimeRangeButtonType = "next" | "previous";

interface Props {
  variant: TimeRangeButtonType;
}

const TimeRangeButton = (props: Props) => {
  const dispatch = useDispatch();
  const { startTime, endTime } = useSelector(
    (state: RootState) => state.timeRange
  );

  const handleClick = () => {
    const timeDiff = endTime - startTime;

    // if previous button
    if (props.variant === "previous") {
      dispatch(setTimeRange([startTime - timeDiff, endTime - timeDiff]));
    }
    // if next button and valid
    else if (dayjs().unix() >= endTime + timeDiff) {
      dispatch(setTimeRange([startTime + timeDiff, endTime + timeDiff]));
    }
    // next button but not valid
    else {
      return;
    }
    dispatch(setTimeRangeOption(0));
  };

  return (
    <IconButton size="small" onClick={handleClick}>
      {props.variant === "previous" ? (
        <ArrowBackIosNewIcon />
      ) : (
        <ArrowForwardIosIcon />
      )}
    </IconButton>
  );
};

export default TimeRangeButton;
