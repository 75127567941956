import { PaletteColorOptions, createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#047cfb",
      dark: "#0063db",
      // light: "#a1d0ff",
    },
    secondary: {
      main: "#faa325",
      // dark: "#fc7322",
      // light: "#ffe088",
    },
    white: {
      main: "#fff",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          fontWeight: 400,
          fontSize: "1rem",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "1rem",
        },
      },
    },
  },
});

declare module "@mui/material/styles" {
  interface PaletteOptions {
    white: PaletteColorOptions;
  }
}

declare module "@mui/material/Button" {
  // <-- Added `/Button` here
  interface ButtonPropsColorOverrides {
    white: true;
  }
}

export const similarColors: string[] = [
  theme.palette.primary.main,
  theme.palette.secondary.main,
  "#00a5ac",
  "#fa0062",
  "#8404fb",
  "#f4a7a6",
  "#7f8c8d",
];
