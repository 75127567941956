import { Sync, SyncDisabled } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toggleAutoUpdate } from "../features/autoUpdateSlice";
import { RootState } from "../store";

const AutoUpdateButton = () => {
  const dispatch = useDispatch();
  const isAutoUpdateOpen = useSelector(
    (state: RootState) => state.autoUpdate.isOpen
  );

  return (
    //MOBILE?
    <Button
      // color={isAutoUpdateOpen ? "success" : "inherit"}
      onClick={() => dispatch(toggleAutoUpdate())}
      // size="small"
      startIcon={isAutoUpdateOpen ? <Sync /> : <SyncDisabled />}
      variant="outlined"
    >
      Auto Update
    </Button>
  );
};

export default AutoUpdateButton;
