import { PageHeader } from "../components/PageHeader";

const Preferences = () => {
  return (
    <div>
      <PageHeader title="Preferences" />
    </div>
  );
};

export default Preferences;
