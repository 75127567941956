import { IconButton, InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useRef } from "react";

interface Props {
  onSubmit: (value: string) => void;
  placeholder?: string;
  width?: number;
  defaultValue?: string;
}

const SearchBar = (props: Props) => {
  const value = useRef("");

  return (
    <Paper
      component={"form"}
      onSubmit={(e) => {
        e.preventDefault();
        props.onSubmit(value.current);
      }}
      sx={{
        pl: 1,
        display: "flex",
        alignItems: "center",
        width: props.width ?? "100%",
        borderRadius: "0.3rem",
        border: "1px solid #cfcfcf",
        boxShadow: "none",
      }}
    >
      {/* <ContactMailOutlinedIcon sx={{ mx: "10px", color: "grey" }} /> */}
      <InputBase
        type="email"
        size="medium"
        required
        sx={{ ml: 1, flex: 1 }}
        placeholder={props.placeholder}
        onChange={(e) => (value.current = e.target.value)}
        defaultValue={props.defaultValue}
      />
      <IconButton type="submit" aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default SearchBar;
