import { MenuItem } from "@mui/material";
import dayjs from "dayjs";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { getComponentById } from "../../utils/componentUtils";
import logo from "../../assets/logo/linaritLogoWithText.png";

interface Props {
  rows: any[];
  columns: any[];
  columnGroups: any[];
  startTime: number;
  endTime: number;
  componentId: number;
}

/**
 * @returns MenuItem that exports table of the reports page.
 */
export const PdfExportMenuItem = (props: Props) => {
  const onClick = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
    });

    // Layout configurations
    const pdfWidth = doc.internal.pageSize.getWidth();
    const pdfHeight = doc.internal.pageSize.getHeight();
    const schemaMargin = 0.2;
    const pageTopMargin = 0.7;
    const textGap = 0.2;

    // Other infos
    const timeRangeInfo =
      dayjs.unix(props.startTime).format("L LT") +
      " - " +
      dayjs.unix(props.endTime).format("L LT");
    const componentName = getComponentById(props.componentId)!.name;
    const componentInfo = componentName
      ? componentName + " / " + props.componentId
      : String(props.componentId);

    // Add title to the current (first) page
    const titleHeight = addTitle();

    // Add the table to the pages
    autoTable(doc, {
      head: [
        // 👇 column groups
        [
          {
            content: "",
            colSpan: 1,
            styles: {
              fillColor: [255, 255, 255],
              lineWidth: 0,
            },
          },
          ...props.columnGroups.map((cg) => ({
            content: cg.groupId,
            colSpan: cg.groupId.includes("Demand") ? 2 : 4,
          })),
        ],
        // 👇 columns
        props.columns.map((column) => column.headerName!),
      ],
      body: props.rows.map((row) =>
        props.columns.map((column) =>
          column.field === "time"
            ? dayjs.unix(row[column.field]).format("L LT")
            : row[column.field]
        )
      ),
      headStyles: {
        halign: "center",
        valign: "middle",
        lineWidth: 0.01,
      },
      bodyStyles: {
        halign: "right",
      },
      columnStyles: {
        0: { halign: "center" },
      },
      startY: titleHeight + textGap,
      rowPageBreak: "avoid",
      willDrawPage: (data) => addSchema(data.pageNumber),
    });
    doc.save("eprobe-reports.pdf");

    // ========================= Helper Functions ============================

    /**
     * Adds the base schema to current page. The base schema should be used
     * for all pages.
     */
    function addSchema(pageNumber: number) {
      doc.setFontSize(8);
      doc.text("E-Probe", schemaMargin, schemaMargin);
      doc.addImage(
        logo,
        "PNG",
        pdfWidth - (0.8 + schemaMargin),
        schemaMargin / 2,
        0.8,
        0.2
      );
      doc.text(timeRangeInfo, schemaMargin, pdfHeight - schemaMargin, {
        align: "left",
      });
      doc.text(String(pageNumber), pdfWidth / 2, pdfHeight - schemaMargin, {
        align: "center",
      });
      doc.text(
        componentInfo,
        pdfWidth - schemaMargin,
        pdfHeight - schemaMargin,
        {
          align: "right",
        }
      );
    }

    /**
     * Adds title of the report which includer page name, time range, component,
     * exporter, export time and url info.
     * @returns Height of the created title.
     */
    function addTitle() {
      const url = window.location.href;
      doc.setFontSize(18);
      doc.text("E-Probe Reports", pdfWidth / 2, pageTopMargin, {
        align: "center",
      });
      doc.setFontSize(10);
      doc.text(
        "Component: " + componentInfo,
        pdfWidth / 2,
        pageTopMargin + textGap * 2,
        {
          align: "center",
        }
      );
      doc.text(timeRangeInfo, pdfWidth / 2, pageTopMargin + textGap * 3, {
        align: "center",
      });
      doc.text(
        `Requested by: ${localStorage.getItem("username")}`,
        pdfWidth / 2,
        pageTopMargin + textGap * 4,
        {
          align: "center",
        }
      );
      doc.text(
        `Created at: ${dayjs().format("L LT")}`,
        pdfWidth / 2,
        pageTopMargin + textGap * 5,
        {
          align: "center",
        }
      );
      doc.textWithLink(url, pdfWidth / 2, pageTopMargin + textGap * 6, {
        url: url,
        align: "center",
      });
      return pageTopMargin + textGap * 6; // height of the title
    }
  };

  return <MenuItem onClick={onClick}>Export PDF</MenuItem>;
};
