import { createSlice } from "@reduxjs/toolkit";
import { isMaxWidthXL } from "../utils/otherUtils";

export interface NavBarStateType {
  isOpen: boolean;
}

const initialState: NavBarStateType = {
  isOpen: !isMaxWidthXL(),
};

export const navBarSlice = createSlice({
  name: "navbar-slice",
  initialState,
  reducers: {
    openNavBar: (state) => {
      state.isOpen = true;
    },
    closeNavBar: (state) => {
      state.isOpen = false;
    },
    toggleNavBar: (state) => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { openNavBar, closeNavBar, toggleNavBar } = navBarSlice.actions;
export default navBarSlice.reducer;
