import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import {
  FormControl,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { setComponentID } from "../features/componentSlice";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import CropSquareOutlinedIcon from "@mui/icons-material/CropSquareOutlined";
import { getComponentById, getComponentsList } from "../utils/componentUtils";
import { memo, useMemo, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

interface IProps {
  optionsType?: "device" | "all";
}

const ComponentTreeSelect = ({ optionsType = "all" }: IProps) => {
  const dispatch = useDispatch();
  const selectedComponentID = useSelector(
    (state: RootState) => state.component.id
  )!;
  const selectedComponent = useMemo(
    () => getComponentById(selectedComponentID)!,
    [selectedComponentID]
  );
  const componentStructure = useSelector(
    (state: RootState) => state.componentStructure.structure
  );
  const componentsList = useMemo(
    () => getComponentsList(componentStructure),
    [componentStructure]
  );

  const [shrinkedGroupIds, setShrinkedGroupIds] = useState<Number[]>([]);

  function onSelectChange(e: SelectChangeEvent<number>) {
    const value = e.target.value as number;
    dispatch(setComponentID(value));
  }

  function onExpandShrink(groupId: Number) {
    let newShrinkedGroupIds: Number[] = [];
    if (shrinkedGroupIds.includes(groupId))
      shrinkedGroupIds.forEach((gid) => {
        if (gid !== groupId) newShrinkedGroupIds.push(gid);
      });
    else newShrinkedGroupIds = [...shrinkedGroupIds, groupId];
    setShrinkedGroupIds(newShrinkedGroupIds);
  }

  function getMenuItems() {
    const menuItems: any = [];
    if (optionsType === "all") prepareHierarchicalList();
    else prepareDeviceList();
    return menuItems;

    function prepareDeviceList() {
      componentsList
        .filter((c) => c.type !== "group")
        .forEach((component) => {
          menuItems.push(
            <MenuItem
              disableGutters
              disableTouchRipple
              key={component.id}
              value={component.id}
              sx={{ pl: 1.5, pr: 2 }}
            >
              {ComponentIcon(component.type)}
              {ComponentText(component.id, component.name)}
            </MenuItem>
          );
        });
    }

    function prepareHierarchicalList(components = componentStructure) {
      for (const component of components) {
        menuItems.push(
          <MenuItem
            disableGutters
            disableTouchRipple
            key={component.id}
            value={component.id}
            sx={{
              pl: 1.5 + 2 * component.depth,
              pr: 1.5,
            }}
          >
            {ComponentIcon(component.type)}
            {ComponentText(component.id, component.name)}
            {component.type === "group" && ExpandShrinkButton(component.id)}
          </MenuItem>
        );
        // Get select options for childs of this component before the sibling components
        if (
          component.type === "group" &&
          !shrinkedGroupIds.includes(component.id)
        )
          prepareHierarchicalList(component.childs);
      }
    }
  }

  const ComponentIcon = (componentType: "group" | "T1" | "T2") => (
    <ListItemIcon sx={{ cursor: "pointer", minWidth: "unset !important" }}>
      {componentType === "group" ? (
        <DeviceHubIcon />
      ) : componentType === "T1" ? (
        <CropSquareOutlinedIcon />
      ) : (
        <FiberManualRecordOutlinedIcon />
      )}
    </ListItemIcon>
  );

  const ComponentText = (componentId: Number, componentName: String | null) => (
    <ListItemText sx={{ m: 0, p: 0, pl: 0.5 }}>
      {componentName ?? componentId.toString()}
    </ListItemText>
  );

  const ExpandShrinkButton = (groupId: Number) => (
    <IconButton
      disableTouchRipple
      sx={{
        width: 25,
        height: 25,
        ml: 5,
      }}
      onClick={(e) => {
        e.stopPropagation();
        onExpandShrink(groupId);
      }}
    >
      {shrinkedGroupIds.includes(groupId) ? (
        <ExpandMoreIcon />
      ) : (
        <ExpandLessIcon />
      )}
    </IconButton>
  );

  return (
    <FormControl>
      <InputLabel>Component</InputLabel>
      <Select
        value={selectedComponentID}
        renderValue={(value) =>
          ComponentText(selectedComponent.id, selectedComponent.name)
        }
        startAdornment={ComponentIcon(selectedComponent.type)}
        onChange={onSelectChange}
        size="small"
        label="Component"
        sx={{ minWidth: "8rem" }}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: "60%",
              maxWidth: "50rem",
              mt: 0.75,
            },
          },
        }}
        inputProps={{
          sx: {
            display: "flex",
            alignItems: "center",
            gap: 1,
          },
        }}
      >
        {getMenuItems()}
      </Select>
    </FormControl>
  );
};

export default memo(ComponentTreeSelect);
