import ReactECharts from "echarts-for-react";
import { useEffect, useRef, useState } from "react";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { commonChartOptions } from "../../assets/config/commonChartOptions";
import { getComponentById } from "../../utils/componentUtils";
import {
  getShortMeasurement,
  getVirtualMeasurement,
} from "../../services/analyticsServiceAPI";
import dayjs from "dayjs";
import { prepareDynamicHeatmapData } from "../../utils/chartUtils";
import { MeasurementsAPIResponse } from "../../types/analyticsResponseTypes";
import DynamicHeatmapChart from "../../components/charts/DynamicHeatmap";
import { Button, TextField } from "@mui/material";

const DynamicHeatmapCard = () => {
  const dynamicHeatmapRef = useRef<ReactECharts>(null);
  const [yTicks, setYTicks] = useState(4);
  const [xTicks, setXTicks] = useState(4);
  const componentId = useSelector((state: RootState) => state.component.id!);
  const [isLoading, setIsLoading] = useState(true);
  const measurements = useRef<MeasurementsAPIResponse>([]);
  const [timeRange, setTimeRange] = useState(prepareTimeRange("day"));

  useEffect(() => {
    let mounted = true;
    let controller = new AbortController();
    fetch();
    async function fetch() {
      setIsLoading(true);
      const [startTime, endTime] = timeRange!;
      try {
        measurements.current =
          getComponentById(componentId)!.type === "group"
            ? await getVirtualMeasurement(
                startTime,
                endTime,
                componentId,
                "auto",
                undefined,
                controller.signal
              )
            : await getShortMeasurement(
                startTime,
                endTime,
                componentId,
                "auto",
                undefined,
                controller.signal
              );
        if (mounted) {
          updateDynamicHeatmap(
            measurements.current,
            startTime,
            endTime,
            yTicks,
            xTicks
          );
          setIsLoading(false);
        }
      } catch (error) {
        if (mounted) {
          console.error(error);
          setIsLoading(false);
        }
      }
    }
    return () => {
      mounted = false;
      controller.abort();
    };
  }, [componentId, timeRange]); // eslint-disable-line

  useEffect(() => {
    const [startTime, endTime] = timeRange;
    updateDynamicHeatmap(
      measurements.current,
      startTime,
      endTime,
      xTicks,
      yTicks
    );
  }, [xTicks, yTicks]); // eslint-disable-line

  useEffect(() => {
    const dynamicHeatmapInstance =
      dynamicHeatmapRef.current!.getEchartsInstance();
    if (isLoading)
      dynamicHeatmapInstance.showLoading(commonChartOptions.loadSpinner);
    else dynamicHeatmapInstance.hideLoading();
  }, [isLoading]);

  function prepareTimeRange(range: "day" | "week" | "month") {
    let endTime = dayjs().unix(); // now
    let startTime = dayjs.unix(endTime).subtract(1, range).unix();
    return [startTime, endTime];
  }

  const updateDynamicHeatmap = (
    measurements: MeasurementsAPIResponse,
    startTime: number,
    endTime: number,
    yPartCount: number,
    xPartCount: number
  ) => {
    const dynamicHeatmapData = prepareDynamicHeatmapData(
      measurements,
      startTime,
      endTime,
      yPartCount,
      xPartCount
    );
    const isNoData = !dynamicHeatmapData.serieData.length;
    dynamicHeatmapRef.current!.getEchartsInstance().setOption({
      title: {
        id: "noData",
        show: isNoData,
      },
      series: [
        {
          name: "Active Energy",
          data: dynamicHeatmapData.serieData.map((d) => [
            d[0],
            d[1],
            d[2].toFixed(1),
          ]),
        },
      ],
      xAxis: {
        show: !isNoData,
        data: dynamicHeatmapData.xData.map((x) => x.toFixed(2)),
        name: "minutes",
        nameLocation: "center",
        nameGap: 25,
      },
      yAxis: {
        show: !isNoData,
        data: dynamicHeatmapData.yData,
      },
      visualMap: {
        show: !isNoData,
        max: dynamicHeatmapData.maxValue,
      },
    });
  };

  function onTicksChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    axis: "x" | "y"
  ) {
    const value = +event.target.value;
    if (value < 1 || value > (axis === "x" ? 24 : 10) || isNaN(value)) return;
    if (axis === "y") setYTicks(value);
    else setXTicks(value);
  }

  return (
    <div className="card p-4 sm:p-8 h-80 sm:h-[30rem] flex flex-col w-full lg:w-1/2">
      <div className="flex justify-between h-8">
        <div className="flex h-full gap-1">
          <TextField
            type="number"
            size="small"
            label="Divide Y Axis"
            sx={{ width: 110 }}
            InputProps={{
              inputProps: { style: { height: "100%" } },
            }}
            disabled={isLoading}
            value={yTicks}
            onChange={(e) => onTicksChange(e, "y")}
          />
          <TextField
            type="number"
            size="small"
            label="Divide X Axis"
            sx={{ width: 110 }}
            InputProps={{
              inputProps: { style: { height: "100%" } },
            }}
            disabled={isLoading}
            value={xTicks}
            onChange={(e) => onTicksChange(e, "x")}
          />
        </div>
        <div className="flex">
          <Button
            children={"Last Day"}
            size="small"
            sx={{ fontSize: 14 }}
            onClick={() => setTimeRange(prepareTimeRange("day"))}
            disabled={isLoading}
          />
          <Button
            sx={{ fontSize: 14 }}
            children={"Last Week"}
            size="small"
            onClick={() => setTimeRange(prepareTimeRange("week"))}
            disabled={isLoading}
          />
          <Button
            sx={{ fontSize: 14 }}
            children={"Last Month"}
            size="small"
            onClick={() => setTimeRange(prepareTimeRange("month"))}
            disabled={isLoading}
          />
        </div>
      </div>
      <div className="flex-1">
        <DynamicHeatmapChart chartRef={dynamicHeatmapRef} />
      </div>
    </div>
  );
};

export default DynamicHeatmapCard;
