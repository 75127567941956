import { baseClient } from "./axiosConfig";
import {
  CircuitEnergyConsumptionAPIResponse,
  DisaggregatedsWithIntervalAPIResponse,
  DisaggregatedsWithoutIntervalAPIResponse,
  MeasurementsAPIResponse,
} from "../types/analyticsResponseTypes";
import { getRequestInterval } from "../utils/otherUtils";
import { Interval } from "../types/analyticsResponseTypes";

const URL_GET_CIRCUIT_ENERGY_CONSUMPTION =
  process.env.REACT_APP_URL_GET_CIRCUIT_ENERGY_CONSUMPTION!;
const URL_GET_MEASUREMENTS = process.env.REACT_APP_URL_GET_MEASUREMENTS!;
const URL_GET_SHORT_MEASUREMENTS =
  process.env.REACT_APP_URL_GET_SHORT_MEASUREMENTS!;
const URL_GET_VIRTUAL_MEASUREMENTS =
  process.env.REACT_APP_URL_GET_VIRTUAL_MEASUREMENTS!;
const URL_GET_DISAGGREGATED = process.env.REACT_APP_URL_GET_DISAGGREGATED!;

export async function getCircuitEnergyConsumption(
  startTime: number,
  endTime: number,
  componentIds: number[],
  abortSignal?: AbortSignal
): Promise<CircuitEnergyConsumptionAPIResponse> {
  const { data, status } = await baseClient.post(
    URL_GET_CIRCUIT_ENERGY_CONSUMPTION,
    {
      meter_ids: componentIds,
      start_time: startTime,
      end_time: endTime,
    },
    { signal: abortSignal }
  );
  if (status !== 200) return [];
  return data;
}

export async function getMeasurement(
  startTime: number,
  endTime: number,
  componentId: number,
  interval: Interval | "auto" | undefined,
  lines: Array<"L1" | "L2" | "L3"> = ["L1", "L2", "L3"],
  abortSignal?: AbortSignal
): Promise<MeasurementsAPIResponse> {
  if (interval === "auto") {
    interval = getRequestInterval(startTime, endTime);
  }
  const { data, status } = await baseClient.post(
    URL_GET_MEASUREMENTS,
    {
      meter_id: componentId,
      start_time: startTime,
      end_time: endTime,
      interval: interval,
      lines: lines,
    },
    { signal: abortSignal }
  );
  if (status !== 200) return [];
  if (data.length === 250)
    return [
      ...data,
      ...(await getMeasurement(
        data[data.length - 1].calc_time + 1,
        endTime,
        componentId,
        interval,
        lines,
        abortSignal
      )),
    ];
  else return data;
}

export async function getShortMeasurement(
  startTime: number,
  endTime: number,
  componentId: number,
  interval: Interval | "auto" | undefined,
  lines: Array<"L1" | "L2" | "L3"> = ["L1", "L2", "L3"],
  abortSignal?: AbortSignal
): Promise<MeasurementsAPIResponse> {
  if (interval === "auto") {
    interval = getRequestInterval(startTime, endTime);
  }
  const { data, status } = await baseClient.post(
    URL_GET_SHORT_MEASUREMENTS,
    {
      meter_id: componentId,
      start_time: startTime,
      end_time: endTime,
      interval: interval,
      lines: lines,
    },
    {
      signal: abortSignal,
    }
  );
  if (status !== 200) return [];
  if (data.length === 250)
    return [
      ...data,
      ...(await getShortMeasurement(
        data[data.length - 1].calc_time + 1,
        endTime,
        componentId,
        interval,
        lines,
        abortSignal
      )),
    ];
  else return data;
}

export async function getVirtualMeasurement(
  startTime: number,
  endTime: number,
  componentId: number,
  interval: Interval | "auto" | undefined,
  lines: Array<"L1" | "L2" | "L3"> = ["L1", "L2", "L3"],
  abortSignal?: AbortSignal
): Promise<MeasurementsAPIResponse> {
  if (interval === "auto") {
    interval = getRequestInterval(startTime, endTime);
  }
  const { data, status } = await baseClient.post(
    URL_GET_VIRTUAL_MEASUREMENTS,
    {
      meter_id: componentId,
      start_time: startTime,
      end_time: endTime,
      interval: interval,
      lines: lines,
    },
    {
      signal: abortSignal,
    }
  );
  if (status !== 200) return [];
  if (data.length === 250)
    return [
      ...data,
      ...(await getVirtualMeasurement(
        data[data.length - 1].calc_time + 1,
        endTime,
        componentId,
        interval,
        lines,
        abortSignal
      )),
    ];
  else return data;
}

export async function getDisaggregated(
  startTime: number,
  endTime: number,
  componentId: number,
  modelType: string,
  interval: Interval | undefined,
  abortSignal?: AbortSignal
): Promise<
  | DisaggregatedsWithIntervalAPIResponse
  | DisaggregatedsWithoutIntervalAPIResponse
> {
  const { data } = await baseClient.post(
    URL_GET_DISAGGREGATED,
    {
      meter_id: componentId,
      start_time: startTime,
      end_time: endTime,
      model_type: modelType,
      interval: interval,
    },
    {
      signal: abortSignal,
    }
  );
  return data;
}
