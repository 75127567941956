import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  Typography,
} from "@mui/material";

import { GetValueAPIResponse } from "../../../types/ldmResponseTypes";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import { configurationLog } from "../../../services/ldmServiceAPI";
import { Loading } from "../../../components/Loading";
import { PropertiesForm } from "./PropertiesForm";
import {
  changeParentGroup,
  changePreMeter,
  changeName,
} from "../../../utils/componentUtils";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  removeDetachedNodeId,
  setComponentStructureForFlow,
} from "../../../features/topologySlice";
import { deepCopy } from "../../../utils/otherUtils";

interface Props {
  initialProps: {
    [id: string]: GetValueAPIResponse;
  };
  editedProps: {
    [id: string]: GetValueAPIResponse["editable"];
  };
  setEditedProps: React.Dispatch<
    React.SetStateAction<{
      [id: string]: GetValueAPIResponse["editable"];
    }>
  >;
}

export const TopologySidebar = (props: Props) => {
  const dispatch = useDispatch();
  const componentId = String(
    useSelector((state: RootState) => state.component.id)!
  );
  const { componentStructureForFlow } = useSelector(
    (state: RootState) => state.topology
  );
  const [expanded, setExpanded] = useState<"props" | "confLogs" | false>(
    "props"
  );
  const [confLogs, setConfLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { initialProps, editedProps, setEditedProps } = props;

  useEffect(() => {
    if (componentId === "") {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    configurationLog(Number(componentId)).then((data) => {
      setConfLogs(data);
      setIsLoading(false);
    });
  }, [componentId]); //eslint-disable-line

  const onAccordionChange = (acc: typeof expanded) =>
    setExpanded(expanded === acc ? false : acc);

  const onPropertyChange = (event: any) => {
    let propName: string = event.target.name;
    let propInitialValue = initialProps[componentId]["editable"][propName];
    let propNewValue: string | number | null = event.target.value as string;

    if (propNewValue === "") propNewValue = null;
    // if it is a property which have a number value
    else if (
      [
        "parent_group_id",
        "pre_meter_id",
        "phase_number",
        "state",
        "reporter_id",
      ].includes(propName)
    ) {
      propNewValue = +propNewValue; // convert to number
      if (isNaN(propNewValue)) return;
    }
    // Update current version of Component Structure (not global state until save it)
    if (propName === "parent_group_id") {
      dispatch(
        setComponentStructureForFlow(
          changeParentGroup(
            Number(componentId),
            Number(propNewValue!),
            deepCopy(componentStructureForFlow)
          )
        )
      );
      dispatch(removeDetachedNodeId(componentId));
    } else if (propName === "pre_meter_id") {
      dispatch(
        setComponentStructureForFlow(
          changePreMeter(
            Number(componentId),
            Number(propNewValue!),
            deepCopy(componentStructureForFlow)
          )
        )
      );
    } else if (propName === "name") {
      dispatch(
        setComponentStructureForFlow(
          changeName(
            Number(componentId),
            String(propNewValue),
            deepCopy(componentStructureForFlow)
          )
        )
      );
    }
    // Updates the object that stores the edited properties
    let editedPropsClone: typeof editedProps = deepCopy(editedProps);
    // If the edition is same with the initial version of the property
    if (propInitialValue === propNewValue) {
      delete editedPropsClone[componentId][propName]; // then there is no change for this prop.
      // If there is no change left on this component
      if (Object.keys(editedPropsClone[componentId]).length === 0)
        delete editedPropsClone[componentId]; // then there is no change for this component.
    }
    // If the edition is different than the initial version of the property
    else {
      // If first edit for this component
      if (!(componentId in editedPropsClone))
        editedPropsClone[componentId] = {};
      editedPropsClone[componentId][propName] = propNewValue;
    }
    setEditedProps(editedPropsClone); // update edits
  };

  return (
    <div className="h-full w-full lg:w-[22rem] flex flex-col rounded-xl gap-2">
      {isLoading ? (
        <Loading variant="local" />
      ) : componentId === "" ? null : (
        <>
          {/* Properties */}
          <div className="card p-0">
            <Accordion
              expanded={expanded === "props"}
              onChange={() => onAccordionChange("props")}
              sx={{
                overflow: "auto",
                minHeight: 48,
              }}
              square={true}
              disableGutters
            >
              <AccordionSummary
                children="Properties"
                expandIcon={<ExpandMoreIcon />}
                className="shadow-sm"
                sx={{
                  position: "sticky",
                  top: 0,
                  bgcolor: "background.default",
                  zIndex: 10,
                }}
              />
              <AccordionDetails>
                <PropertiesForm
                  componentId={componentId}
                  onPropertyChange={onPropertyChange}
                  properties={{
                    editable: {
                      ...initialProps[componentId].editable,
                      ...(editedProps[componentId] ?? {}),
                    },
                    not_editable: initialProps[componentId].not_editable,
                  }}
                  componentStructureForFlow={componentStructureForFlow}
                />
              </AccordionDetails>
            </Accordion>
          </div>

          {/* Configuration Logs */}
          <div className="card p-0 overflow-auto">
            <Accordion
              expanded={expanded === "confLogs"}
              onChange={() => onAccordionChange("confLogs")}
              sx={{ minHeight: 48 }}
              square={true}
              disableGutters
            >
              <AccordionSummary
                children="Configuration Logs"
                expandIcon={<ExpandMoreIcon />}
                className="shadow-sm"
                sx={{
                  position: "sticky",
                  top: 0,
                  bgcolor: "background.default",
                  zIndex: 10,
                }}
              />
              <AccordionDetails>
                <List disablePadding>
                  {confLogs.map((log, idx) => (
                    <ListItem
                      disableGutters
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                      }}
                      divider
                      key={idx}
                    >
                      {Object.entries(log).map((entry, idx) => (
                        <Typography
                          variant="body2"
                          key={idx}
                          gutterBottom
                          children={entry[0] + ": " + entry[1]}
                        />
                      ))}
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          </div>
        </>
      )}
    </div>
  );
};
