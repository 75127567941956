import { Button, InputAdornment, TextField } from "@mui/material";
import { NodeResizer } from "@reactflow/node-resizer";
import "@reactflow/node-resizer/dist/style.css";
import { memo, useState } from "react";
import { NodeProps, NodeToolbar, useReactFlow } from "reactflow";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import { toast } from "react-toastify";

const ResizableGroupNode = (props: NodeProps) => {
  const reactFlowInstance = useReactFlow();
  const [hasParent, setHasParent] = useState(
    reactFlowInstance.getNode(props.id)!.parentNode === undefined
  );

  const handleDelete = () => {
    if (props.id === "1") {
      toast.error(
        "You can not delete the root node. Please delete a child node instead."
      );
    } else {
      console.log("node", reactFlowInstance.getNode(props.id)!);
      reactFlowInstance.deleteElements({
        nodes: [reactFlowInstance.getNode(props.id)!],
      });
    }
  };

  const handleDetach = () => {
    reactFlowInstance.setNodes((oldNodes) =>
      oldNodes.map((node) => {
        if (node.id === props.id) {
          node.parentNode = undefined;
          node.extent = undefined;
          node.position = node.positionAbsolute!;
        }
        return node;
      })
    );
    setHasParent(false);
  };

  if (
    (reactFlowInstance.getNode(props.id)!.parentNode === undefined) ===
    hasParent
  ) {
    setHasParent(!hasParent);
  }

  // const handleUngroup = () => {
  //   const nodes = reactFlowInstance.getNodes();
  //   const childNodes: Node[] = [];
  //   for (const node of nodes) {
  //     if (node.parentNode === props.id) {
  //       removeParent(node);
  //       childNodes.push(node);
  //     }
  //   }
  //   reactFlowInstance.setNodes((nodes) =>
  //     nodes.reduce((array: Node[], oldNode) => {
  //       if (oldNode.id !== props.id) {
  //         array.push(
  //           childNodes.find((childNode) => childNode.id === oldNode.id) ??
  //             oldNode
  //         );
  //       }
  //       return array;
  //     }, [])
  //   );
  // };

  /*
  const handleLabelChange = (label: string) => {
    reactFlowInstance.setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === props.id) {
          const newNode = node;
          newNode.data.label = label;
          return newNode;
        }
        return node;
      })
    );
  };
*/
  return (
    <div
      aria-selected={props.selected}
      className={
        " aria-selected:animate-pulse aria-selected:border-gray-400 " +
        " h-full w-full border-gray-400 border-[2px]"
      }
    >
      <NodeToolbar>
        <Button
          color="error"
          disabled={props.id === "1"}
          onClick={handleDelete}
          children={"Delete"}
        />
        {/* <Button onClick={handleUngroup} children={"Ungroup"} /> */}
        <Button
          disabled={!hasParent}
          onClick={handleDetach}
          children={"Detach"}
        />
      </NodeToolbar>
      <NodeResizer
        minWidth={100}
        minHeight={30}
        lineStyle={{ borderWidth: "1px" }}
        color="transparent"
      />
      <TextField
        size="small"
        value={props.data.label}
        InputProps={{
          readOnly: true, // edit'lemek için kaldır
          startAdornment: (
            <InputAdornment position="start">
              <DeviceHubIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default memo(ResizableGroupNode);
