import ReactECharts from "echarts-for-react";
import { memo } from "react";
import { commonChartOptions } from "../../assets/config/commonChartOptions";

interface Props {
  chartRef: React.RefObject<ReactECharts>;
}

const CalendarHeatmap = (props: Props) => {
  const option = {
    title: [
      commonChartOptions.noDataTitle,
      { ...commonChartOptions.title, text: "Last 4 months" },
    ],
    textStyle: commonChartOptions.textStyle,
    animation: false,
    tooltip: {
      valueFormatter: (value: number) => value + " kWh",
    },
    visualMap: {
      min: 0,
      max: 0,
      orient: "horizontal",
      bottom: 0,
      left: 0,
      calculable: true,
    },
    calendar: {
      range: new Date().getFullYear(),
      width: "auto",
      left: 26,
      right: 10,
      top: "25%",
      yearLabel: { show: true, position: "top", margin: 25 },
    },
    series: [
      {
        name: "Active Energy",
        type: "heatmap",
        coordinateSystem: "calendar",
        data: [],
      },
    ],
  };

  return (
    <ReactECharts
      style={{ width: "100%", height: "100%" }}
      ref={props.chartRef}
      option={option}
    />
  );
};
export default memo(CalendarHeatmap);
