import axios from "axios";
import { updateAccessAndRetryRequest } from "./compositeRequests";

const URL_BASE_DEV = process.env.REACT_APP_BASE_DEV_URL;

export const baseClient = axios.create({ baseURL: URL_BASE_DEV });
export const updateAccessClient = axios.create({ baseURL: URL_BASE_DEV });

// Login page also sets the default "Authorization" header of this axios
// instance after a successful login.
baseClient.defaults.headers.common["Authorization"] =
  localStorage.getItem("access-token");

baseClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const requestConfig = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !requestConfig._retry
    ) {
      requestConfig._retry = true;
      return updateAccessAndRetryRequest(requestConfig);
    }
    return Promise.reject(error);
  }
);
