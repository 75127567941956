import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMeasurementsData } from "../../features/measurementsSlice";
import { RootState } from "../../store";
import {
  getVirtualMeasurement,
  getShortMeasurement,
  getCircuitEnergyConsumption,
} from "../../services/analyticsServiceAPI";
import {
  findInnerComponents,
  getComponentById,
} from "../../utils/componentUtils";
import { setCircuitEnergyConsumptionData } from "../../features/circuitEnergyConsumptionSlice";
import { MonitoringChartCards } from "./MonitoringChartCards";
import MonitoringInfoCards from "./MonitoringInfoCards";
import { aggregateWindowEnergies } from "../../utils/chartUtils";
import AutoUpdateButton from "../../components/AutoUpdateButton";
import TimeRange from "../../components/TimeRange/TimeRange";
import ComponentTreeSelect from "../../components/ComponentTreeSelect";
import { PageHeader } from "../../components/PageHeader";
import {
  CircuitEnergyConsumptionAPIResponse,
  MeasurementsAPIResponse,
} from "../../types/analyticsResponseTypes";

const Monitoring = () => {
  const dispatch = useDispatch();
  const { startTime, endTime } = useSelector(
    (state: RootState) => state.timeRange
  );
  const componentId = useSelector((state: RootState) => state.component.id!);
  const [isFetchingConsumptions, setIsFetchingConsumptions] = useState(true);
  const [isFetchingMeasurements, setIsFetchingMeasurements] = useState(true);

  useEffect(() => {
    let mounted = true;
    let controller = new AbortController();
    fetch();

    return () => {
      mounted = false;
      controller.abort();
    };

    async function fetch() {
      setIsFetchingConsumptions(true);
      setIsFetchingMeasurements(true);
      const isGroup = getComponentById(componentId)!.type === "group";
      // Get Measurements
      let measurements: MeasurementsAPIResponse = [];
      try {
        measurements = await (isGroup
          ? getVirtualMeasurement(
              startTime,
              endTime,
              componentId,
              "auto",
              undefined,
              controller.signal
            )
          : getShortMeasurement(
              startTime,
              endTime,
              componentId,
              "auto",
              undefined,
              controller.signal
            ));
        if (mounted) {
          dispatch(setMeasurementsData(measurements));
          setIsFetchingMeasurements(false);
        }
      } catch (error) {
        if (mounted) {
          console.info(error);
          setIsFetchingMeasurements(false);
        }
      }
      // Get Consumptions
      let consumptions: CircuitEnergyConsumptionAPIResponse = [];
      try {
        if (isGroup) {
          const groupConsumptions = aggregateWindowEnergies(measurements);
          const t1Components = findInnerComponents(
            componentId,
            undefined,
            "T1"
          );
          const t1sConsumptions = await Promise.all(
            t1Components.map((t1) =>
              getCircuitEnergyConsumption(
                startTime,
                endTime,
                [t1.id],
                controller.signal
              )
            )
          );
          consumptions = [
            {
              meter_id: componentId,
              lines: {
                L1: {
                  active_energy: groupConsumptions.activeEnergyData,
                  reactive_energy: groupConsumptions.reactiveEnergyData,
                  apparent_energy: groupConsumptions.appearentEnergyData,
                },
              },
              children: t1sConsumptions
                .map((c) => c[0] ?? null)
                .filter((c) => c !== null),
            },
          ];
        } else {
          consumptions = await getCircuitEnergyConsumption(
            startTime,
            endTime,
            [componentId],
            controller.signal
          );
        }
        if (mounted) {
          dispatch(setCircuitEnergyConsumptionData(consumptions));
          setIsFetchingConsumptions(false);
        }
      } catch (error) {
        if (mounted) {
          console.info(error);
          setIsFetchingConsumptions(false);
        }
      }
    }
  }, [startTime, endTime, componentId]); // eslint-disable-line

  return (
    <div className="grid lg:grid-cols-12 grid-cols-1 gap-1 lg:gap-2">
      <div className="lg:col-span-9">
        <PageHeader
          title="Monitoring"
          element={
            <div className="flex gap-2 flex-wrap justify-center items-center">
              <ComponentTreeSelect />
              <TimeRange />
              <AutoUpdateButton />
            </div>
          }
        />
        <MonitoringChartCards
          isFetchingMeasurements={isFetchingMeasurements}
          isFetchingConsumptions={isFetchingConsumptions}
        />
      </div>
      <div className="lg:col-span-3">
        <MonitoringInfoCards componentId={componentId} />
      </div>
    </div>
  );
};

export default Monitoring;
