import React, { useState, useMemo } from "react";
import {
  getComponentsList,
  prepareComponentStructure,
} from "../../../../utils/componentUtils";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { createDevice } from "../../../../services/ldmServiceAPI";
import { setComponentStructureForFlow } from "../../../../features/topologySlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { setComponentStructure } from "../../../../features/componentStructureSlice";
import { useNavigate } from "react-router-dom";

interface FormData {
  device_type: string;
  component_id: number;
  group_id: number;
  pre_meter_id?: number; // make preMeterId optional
}

interface Props {
  setIsDeviceModalOpen: (state: boolean) => void;
}

const AddDeviceForm = (props: Props) => {
  const [formData, setFormData] = useState<FormData>({
    device_type: "",
    component_id: 0,
    group_id: 0,
    pre_meter_id: 0,
  });
  const navigate = useNavigate();
  const componentStructure = useSelector(
    (state: RootState) => state.componentStructure.structure
  );
  const dispatch = useDispatch();

  const selectOptions: { [key: string]: Array<{ value: any; label: any }> } =
    useMemo(() => {
      const components = getComponentsList();
      const deviceTypes = ["T1", "T2"];
      return {
        group_id: components
          .filter((c) => c.type === "group")
          .map((c) => ({ value: c.id, label: c.name ?? c.id })),

        device_type: deviceTypes.map((type) => ({ value: type, label: type })),
      };
    }, [componentStructure]); // eslint-disable-line

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    try {
      e.preventDefault();
      checkPreMeterId(formData);
      await createDevice(
        formData.device_type,
        formData.component_id,
        formData.group_id,
        formData.pre_meter_id
      );
      await updateFlow();
      props.setIsDeviceModalOpen(false);
      navigate(0); // refresh the page
    } catch (error) {
      console.log("Error: ", error);
    }
  }

  async function updateFlow() {
    prepareComponentStructure()
      .then((componentStructure) => {
        dispatch(setComponentStructure(componentStructure));
        dispatch(setComponentStructureForFlow(componentStructure));
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  }

  function checkPreMeterId(formData: FormData) {
    if (formData.device_type === "T1") {
      if (formData.pre_meter_id !== undefined) {
        delete formData.pre_meter_id;
      }
    }
    setFormData(formData);
  }

  function editLabel(label: string) {
    return (label[0].toUpperCase() + label.slice(1)).replaceAll("_", " ");
  }

  return (
    <form onSubmit={handleSubmit} className="grid gap-6 gap-x-3 lg:w-80">
      {Object.entries(formData).map(([key, value]) => {
        switch (key) {
          case "device_type":
            return (
              <FormControl key={key} size="small" required>
                <InputLabel>{editLabel(key)}</InputLabel>
                <Select
                  multiple={false}
                  value={value as any}
                  label={key}
                  sx={{ bgcolor: "white" }}
                  size="small"
                  onChange={(e) =>
                    setFormData({ ...formData, [key]: e.target.value })
                  }
                >
                  {selectOptions[key].map((o) => (
                    <MenuItem key={o.value} value={o.value}>
                      {o.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );

          case "component_id":
            return (
              <TextField
                key={key}
                label={editLabel(key)}
                type="number"
                size="small"
                required
                defaultValue={value}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    component_id: parseInt(e.target.value, 10),
                  })
                }
              />
            );

          case "group_id":
            return (
              <FormControl key={key} size="small" required>
                <InputLabel>{"Group"}</InputLabel>
                <Select
                  multiple={false}
                  value={value as any}
                  label={key}
                  sx={{ bgcolor: "white" }}
                  size="small"
                  onChange={(e) =>
                    setFormData({ ...formData, [key]: e.target.value })
                  }
                >
                  {selectOptions[key].map((o) => (
                    <MenuItem key={o.value} value={o.value}>
                      {o.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          case "pre_meter_id":
            return formData.device_type === "T2" ? (
              <TextField
                key={key}
                label={editLabel(key)}
                type="number"
                required
                size="small"
                defaultValue={value}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    pre_meter_id: parseInt(e.target.value, 10),
                  })
                }
              />
            ) : null;

          default:
            return null;
        }
      })}
      <Button type="submit" variant="contained" color="primary">
        Add Device
      </Button>
    </form>
  );
};

export default AddDeviceForm;
