import { useState } from "react";
import TimeRangeButton from "./TimeRangeButton";
import TimeRangeDialog from "./TimeRangeDialog";
import TimeRangeIconMenu from "./TimeRangeIconMenu";
import TimeRangeSelect from "./TimeRangeSelect";

/**
 * This component includes previous/next time range buttons and a
 * time range options list.
 */
const TimeRange = () => {
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);

  return (
    <div className="flex items-center ">
      <TimeRangeButton variant="previous" />

      {/* On mobile */}
      <TimeRangeIconMenu setDialogOpen={setDialogOpen} />
      {/* On wider screens */}
      <TimeRangeSelect setDialogOpen={setDialogOpen} />

      <TimeRangeButton variant="next" />

      <TimeRangeDialog
        isDialogOpen={isDialogOpen}
        setDialogOpen={setDialogOpen}
      />
    </div>
  );
};

export default TimeRange;
