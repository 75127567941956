import { getDeviceList, getGroupList } from "../services/ldmServiceAPI";
import { store } from "../store";
import { DeviceComponent, GroupComponent } from "../types/componentTypes";
import {
  DeviceListWithPropsAPIResponse,
  GroupListAPIResponse,
} from "../types/ldmResponseTypes";
import { deepCopy } from "./otherUtils";

/**
 * This method fetchs the getGroupList and getDeviceList data from API as
 * GroupList and DeviceListWithProps response data types. Then it produces
 * DeviceComponent and GroupComponent objects from that responses and prepares
 * a hierarchical component structure which means it inserts the child
 * GroupComponents and DeviceComponents into "childs" array attribute of their
 * parent GroupComponent.
 */
export async function prepareComponentStructure(
  groups?: GroupListAPIResponse,
  devices?: DeviceListWithPropsAPIResponse
) {
  if (!groups || !devices) {
    groups = await getGroupList();
    devices = (await getDeviceList([
      "pre_meter_id",
      "parent_group_id",
      "type",
      "name",
    ])) as DeviceListWithPropsAPIResponse;
  }

  const componentStructure: Array<DeviceComponent | GroupComponent> = [];
  const parentlessGroups = groups.filter(
    (group) => group.parent_group_id === null
  )!;

  for (const parentlessGroup of parentlessGroups) {
    const parentlessGroupComponent: GroupComponent = {
      id: parentlessGroup.component_id,
      name: parentlessGroup.name,
      type: "group",
      parentGroupId: null,
      depth: 0,
      childs: [],
    };
    parentlessGroupComponent.childs = prepareRecursively(
      parentlessGroupComponent
    );
    componentStructure.push(parentlessGroupComponent);
  }
  return componentStructure;

  // ----- Helper function -----
  function prepareRecursively(
    parentComponent: GroupComponent
  ): (DeviceComponent | GroupComponent)[] {
    const childComponents: (DeviceComponent | GroupComponent)[] = [];
    const childGroups = groups!.filter(
      (group) => group.parent_group_id === parentComponent.id
    );
    const childDevices = devices!.filter(
      (device) => device.parent_group_id === parentComponent.id
    );
    for (const childGroup of childGroups) {
      const newGroupComponent: GroupComponent = {
        id: childGroup.component_id,
        name: childGroup.name,
        depth: parentComponent.depth + 1,
        parentGroupId: parentComponent.id,
        type: "group",
        childs: [],
      };
      childComponents.push(newGroupComponent);
      newGroupComponent.childs = prepareRecursively(newGroupComponent);
    }
    for (const childDevice of childDevices) {
      const newDeviceComponent: DeviceComponent = {
        id: childDevice.component_id,
        name: childDevice.name,
        depth: parentComponent.depth + 1,
        parentGroupId: parentComponent.id,
        preMeterId: childDevice.pre_meter_id!,
        type: childDevice.type!,
      };
      childComponents.push(newDeviceComponent);
    }
    return childComponents;
  }
}

export function getComponentById(
  componentId: number,
  componentStructure = store.getState().componentStructure.structure
) {
  return searchRecursively(componentStructure);

  function searchRecursively(
    components: (GroupComponent | DeviceComponent)[]
  ): GroupComponent | DeviceComponent | null {
    for (const component of components) {
      if (component.id === componentId) return component;
      else if (component.type === "group") {
        const result = searchRecursively(component.childs);
        if (result) return result;
      }
    }
    return null;
  }
}

export function getLabel(componentId: number) {
  let component = getComponentById(componentId);
  if (!component) throw Error("Invalid componentId!");
  return component.name ?? componentId.toString();
}

/**
 *
 * @param preMeterId The preMeterId of the device component that we want to find
 * @param componentStructure searches in this structure
 * @returns returns the found devices in an array
 */
export function getDevicesByPreMeterId(
  preMeterId: number,
  componentStructure = store.getState().componentStructure.structure
) {
  var foundComponents: typeof componentStructure = [];
  searchRecursively(componentStructure);
  return foundComponents;

  function searchRecursively(
    components: (GroupComponent | DeviceComponent)[]
  ): (GroupComponent | DeviceComponent)[] | null {
    for (const component of components) {
      if (
        (component.type === "T1" || component.type === "T2") &&
        component.preMeterId === preMeterId
      )
        foundComponents.push(component);
      else if (component.type === "group") {
        const result = searchRecursively(component.childs);
        if (result) return result;
      }
    }
    return null;
  }
}

/**
 * Returns only id, name and type of the components
 */
export function getComponentsList(
  componentStructure = store.getState().componentStructure.structure
) {
  const componentList: {
    id: number;
    name: string | null;
    type: (typeof componentStructure)[number]["type"];
  }[] = [];
  fillRecursively(componentStructure);
  return componentList;

  function fillRecursively(components: (GroupComponent | DeviceComponent)[]) {
    for (const component of components) {
      componentList.push({
        id: component.id,
        name: component.name,
        type: component.type,
      });
      if (component.type === "group") fillRecursively(component.childs);
    }
  }
}

/**
 * Returns available group components which allowed to be parent group of given
 * group component. Inner groups of the given group are not selected.
 * Searchs in the given component structure. If you do not provide a structure,
 * then it searchs in the structure which saved in the global state as default.
 */
export function getAvailableParentGroupComponents(
  childGroupId: number,
  componentStructure: (GroupComponent | DeviceComponent)[] = store.getState()
    .componentStructure.structure
) {
  const componentList: { id: number; name: string | null }[] = [];
  fillRecursively(componentStructure);
  return componentList;

  function fillRecursively(components: (GroupComponent | DeviceComponent)[]) {
    for (const component of components) {
      if (component.type === "group" && component.id !== childGroupId) {
        componentList.push({ id: component.id, name: component.name });
        fillRecursively(component.childs);
      }
    }
  }
}

/**
 * Returns available pre meter device components for the given postDevice
 * component. PostDevice is the component that we will change the
 * pre_meter_id property.
 * Searchs in the given component structure. If you do not provide a structure,
 * then it searchs in the structure which saved in the global state as default.
 */
export function getAvailablePreMeterComponents(
  postDeviceId: number,
  componentStructure = store.getState().componentStructure.structure
) {
  const componentList: { id: number; name: string | null }[] = [];
  fillRecursively(componentStructure);
  return componentList;

  function fillRecursively(components: typeof componentStructure) {
    for (const component of components) {
      if (component.type === "group") fillRecursively(component.childs);
      else if (!isPostConnected(component)) {
        componentList.push({ id: component.id, name: component.name });
      }
    }
    // Parametredeki cihazın bulunduğu ağaçta (elektriksel hiyerarşide)
    // kendisinin öncesindeki bağlı cihazlara bakar. postDevice'ı bulursak true döner.
    function isPostConnected(component: DeviceComponent): boolean {
      if (component.id === postDeviceId) return true;
      else if (!component.preMeterId) return false;
      else {
        return isPostConnected(
          getComponentById(
            component.preMeterId,
            componentStructure
          ) as DeviceComponent
        );
      }
    }
  }
}

/**
 * @param componentIdToBeDeleted Id of the component that will be deleted
 * @param componentStructure Searches and deletes the component in this structure
 * @returns Updated, current version of componentStructure.
 */

// TODO: search and look if there is such a component with the given ID
export function deleteComponentById(
  componentIdToBeDeleted: number,
  componentStructure: (GroupComponent | DeviceComponent)[] = store.getState()
    .componentStructure.structure
): (GroupComponent | DeviceComponent)[] {
  return deleteRecursively(componentStructure);

  function deleteRecursively(components: (GroupComponent | DeviceComponent)[]) {
    return components.reduce(
      (
        acc: (GroupComponent | DeviceComponent)[],
        component: GroupComponent | DeviceComponent
      ) => {
        if ("id" in component && component.id === componentIdToBeDeleted) {
          return acc; // Skip this component as it matches the ID to be deleted
        }
        if ("childs" in component) {
          const updatedChilds = deleteComponentById(
            componentIdToBeDeleted,
            component.childs
          );
          const updatedComponent = { ...component, childs: updatedChilds };
          acc.push(updatedComponent);
        } else {
          acc.push(component);
        }

        return acc;
      },
      []
    );
  }
}

/**
 * @param childId Id of the component that will be updated
 * @param newParentId Id of the new group component
 * @param componentStructure Searches and updates this structure
 * @returns Updated, current version of componentStructure
 */
export function changeParentGroup(
  childId: number,
  newParentId: number,
  componentStructure = store.getState().componentStructure.structure
) {
  let childComponent: DeviceComponent | GroupComponent | null = deepCopy(
    getComponentById(childId, componentStructure)
  );
  let newParentComponent = getComponentById(newParentId, componentStructure);
  if (!newParentComponent || !childComponent)
    throw Error("Component not found!");
  if (newParentComponent.type !== "group")
    throw Error("parentId is not a group component!");
  if (childComponent.parentGroupId === newParentId) return componentStructure;
  recursiveHelper(componentStructure);
  return componentStructure;

  function recursiveHelper(components: typeof componentStructure, depth = 0) {
    for (let i = components!.length - 1; i >= 0; i--) {
      const component = components![i];
      component.depth = depth;
      if (component.id === childId && component.parentGroupId !== newParentId)
        components!.splice(i, 1); // removes
      else if (component.id === newParentId) {
        childComponent!.parentGroupId = newParentId;
        (component as GroupComponent).childs.push(childComponent!);
      }
      if (component.type === "group")
        recursiveHelper(component.childs, depth + 1);
    }
  }
}

/**
 * @param targetId Id of the component that will be updated
 * @param newSourceId Id of the new pre_meter
 * @param componentStructure Searches and updates this structure
 * @returns Updated, current version of componentStructure
 */
export function changePreMeter(
  targetId: number,
  newSourceId: number | null,
  componentStructure = store.getState().componentStructure.structure
) {
  recursiveHelper(componentStructure);
  return componentStructure;

  function recursiveHelper(components: typeof componentStructure) {
    for (const component of components!) {
      if (component.id === targetId) {
        if (component.type === "group")
          throw Error("targetId should not belong to a group!");
        else component.preMeterId = newSourceId;
      } else if (component.type === "group") recursiveHelper(component.childs);
    }
  }
}

/**
 * @param targetId Id of the component that will be updated
 * @param newName New name of the component
 * @param componentStructure Searches and updates this structure
 * @returns Updated, current version of componentStructure
 */
export function changeName(
  targetId: number,
  newName: string | null,
  componentStructure = store.getState().componentStructure.structure
) {
  recursiveHelper(componentStructure);
  return componentStructure;

  function recursiveHelper(components: typeof componentStructure) {
    for (const component of components!) {
      if (component.id === targetId) {
        if (newName === "" || newName === null || newName === "null") {
          component.name = null;
        } else {
          component.name = newName;
        }
        return;
      } else if (component.type === "group") recursiveHelper(component.childs);
    }
  }
}

export function hasAnyParentlessT2(
  componentStructure = store.getState().componentStructure.structure
): boolean {
  let hasAny = false;
  recursiveHelper(componentStructure);
  return hasAny;

  function recursiveHelper(components: typeof componentStructure) {
    if (hasAny) return;
    for (const component of components) {
      if (component.type === "group") recursiveHelper(component.childs);
      else if (component.type === "T2" && component.preMeterId === null) {
        hasAny = true;
        return;
      }
    }
  }
}

export function findInnerComponents(
  parentComponentId: number,
  componentStructure = store.getState().componentStructure.structure,
  type?: (typeof componentStructure)[number]["type"]
): (DeviceComponent | GroupComponent)[] {
  const parentComponent = getComponentById(parentComponentId);
  if (!parentComponent)
    throw Error("The component could not found with given id!");
  else if (parentComponent.type !== "group")
    throw Error("The desired component is not a group!");
  else {
    const componentList: (DeviceComponent | GroupComponent)[] = [];
    for (const component of parentComponent.childs) {
      if (type === undefined || type === component.type) {
        componentList.push(component);
      }
      if (component.type === "group")
        componentList.push(
          ...findInnerComponents(component.id, undefined, type)
        );
    }
    return componentList;
  }
}
