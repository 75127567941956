import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { logoutFromApp } from "../services/compositeRequests";

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-center items-center h-screen gap-4 bg-dashboard p-8">
      <Typography variant="h3">Whoops! Something went wrong.</Typography>
      <Typography variant="body1">
        Page could not be loaded. Please try again later.
      </Typography>
      <Button
        variant="contained"
        onClick={() => {
          logoutFromApp(false);
          navigate("/login");
        }}
        children="Back To Login Page"
      />
    </div>
  );
};

export default ErrorPage;
