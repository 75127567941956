import { AccessTime } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  setTimeRangeOption,
  timeRangeOptions,
} from "../../features/timeRangeSlice";

interface Props {
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * This component will be displayed on mobile devices instead of
 * "TimeRangeSelect" component.
 */
const TimeRangeIconMenu = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const { timeRangeOptionId } = useSelector(
    (state: RootState) => state.timeRange
  );

  const handleOptionSelect = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    const value = e.currentTarget.value;
    if (value === 0) {
      props.setDialogOpen(true);
    }
    dispatch(setTimeRangeOption(value));
  };

  return (
    <div>
      <IconButton
        sx={{ display: { sm: "none" } }}
        size="small"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        aria-controls={Boolean(anchorEl) ? "time-range-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl) ? "true" : undefined}
      >
        <AccessTime />
      </IconButton>

      <Menu
        id="time-range-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
      >
        {timeRangeOptions.map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
            onClick={handleOptionSelect}
            selected={option.id === timeRangeOptionId}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default TimeRangeIconMenu;
