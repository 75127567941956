import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import linaritLogoWithText from "../assets/logo/linaritLogoWithText.png";
import Footer from "../components/Dashboard/layouts/Footer";
import { loginToApp } from "../services/compositeRequests";
import { Toast } from "../components/Toast";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LoginIcon from "@mui/icons-material/Login";

export default function Login() {
  const navigate = useNavigate();
  const [errorToast, setErrorToast] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setErrorToast(false);
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const isLoginSuccessful = await loginToApp(
      data.get("email")!.toString(),
      data.get("password")!.toString()
    );
    if (isLoginSuccessful) navigate("/");
    else setErrorToast(true);
    setLoading(false);
  };

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <div className="flex flex-col items-center justify-center sm:gap-4 max-w-[30rem] pb-[10%]">
        <img
          className="w-36"
          src={linaritLogoWithText}
          alt="linarit-logo-with-text"
        />
        <div className="flex flex-col items-center gap-4 p-4 sm:p-8 sm:border sm:shadow-lg sm:rounded-lg">
          <Typography component="h1" variant="h5" color={"primary.dark"}>
            E-Probe Login
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate>
            <TextField
              margin="normal"
              fullWidth
              label="Email"
              name="email"
              autoComplete="email"
              placeholder="user@linarit.com"
              autoFocus
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {<EmailOutlinedIcon />}
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              placeholder="********"
              autoComplete="current-password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {<LockOutlinedIcon />}
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((show) => !show)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3 }}
              disabled={isLoading}
              loading={isLoading}
              startIcon={<LoginIcon />}
              children={<span>Login</span>}
            />
          </Box>
        </div>
        <Toast
          open={errorToast}
          setOpen={setErrorToast}
          variant="error"
          message="Incorrect email or password."
        />
      </div>
      <Footer />
    </div>
  );
}
