import { Navigate, Route, Routes } from "react-router-dom";
import { navData } from "../../../assets/data/navData";

const routesData = [...navData.primaryPages, ...navData.otherPages].filter(
  (d) => d.component
);

const Main = () => {
  return (
    <main className="min-h-[calc(100vh-4rem)] p-2 pt-0 z-30">
      <Routes>
        <Route
          path="/*"
          element={<Navigate to={navData.primaryPages[0].path} replace />}
        />
        {routesData.map(
          (r) =>
            r.component && (
              <Route key={r.path} path={r.path} element={<r.component />} />
            )
        )}
      </Routes>
    </main>
  );
};

export default Main;
