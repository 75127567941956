import { memo, useState } from "react";
import {
  Handle,
  NodeProps,
  NodeToolbar,
  Position,
  useReactFlow,
} from "reactflow";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  getComponentById,
  getComponentsList,
} from "../../../../utils/componentUtils";
import { addDetachedNodeId } from "../../../../features/topologySlice";
import { toast } from "react-toastify";
import { DeviceComponent } from "../../../../types/componentTypes";

const DeviceNode = (props: NodeProps) => {
  const dispatch = useDispatch();
  const reactFlowInstance = useReactFlow();
  const [hasParent, setHasParent] = useState(
    reactFlowInstance.getNode(props.id)!.parentNode === undefined
  );
  const isOnline = useSelector(
    (state: RootState) => state.componentStructure.connectionStatus
  ).find((cs) => String(cs.component_id) === props.id)?.isOnline;
  const deviceType = getComponentById(Number(props.id))!.type as "T1" | "T2";
  const { componentStructureForFlow } = useSelector(
    (state: RootState) => state.topology
  );

  const handleDelete = () => {
    var deleteErrorOccurred = false;
    // tipi t2 veya t1 olan tüm componentleri al
    const componentList = getComponentsList(componentStructureForFlow).filter(
      (t) => t.type === "T2" || t.type === "T1"
    );
    // eğer silinecek componentin parentGroupId'si varsa ve tipi T1 ise herhangi bir cihazın parenti mi diye bakılır, eğer true ise cihaz silinmez
    if (
      componentList.some((t) => t.type === "T1" && t.id === Number(props.id))
    ) {
      if (
        componentList.some(
          (t) =>
            (
              getComponentById(
                t.id,
                componentStructureForFlow
              ) as DeviceComponent
            ).preMeterId === Number(props.id)
        )
      ) {
        toast.error(
          "This T1 device is a parent of another device. Please delete the child device/devices or change their parent group ids."
        );
        deleteErrorOccurred = true;
        return;
      }
    }
    if (!deleteErrorOccurred) {
      reactFlowInstance.deleteElements({
        nodes: [reactFlowInstance.getNode(props.id)!],
      });
    }
  };

  const handleDetach = () => {
    reactFlowInstance.setNodes((oldNodes) =>
      oldNodes.map((node) => {
        if (node.id === props.id) {
          node.parentNode = undefined;
          node.extent = undefined;
          node.position = node.positionAbsolute!;
        }
        return node;
      })
    );
    dispatch(addDetachedNodeId(props.id));
    setHasParent(false);
  };

  if (
    (reactFlowInstance.getNode(props.id)!.parentNode === undefined) ===
    hasParent
  ) {
    setHasParent(!hasParent);
  }

  return (
    <div
      className={
        (props.selected ? " animate-pulse " : " animate-none ") +
        (deviceType === "T2" ? " rounded-full " : " rounded-md ") +
        " bg-transparent border-2 border-gray-500 " +
        " h-full w-full flex justify-center items-center "
      }
    >
      {/* online/offline indicator */}
      <div
        className={
          (isOnline ? " bg-green-500 " : " bg-red-500 ") +
          (deviceType === "T2"
            ? " right-[3px] top-[3px] "
            : " -right-[6px] -top-[6px] ") +
          " border-[2px] border-gray-500 w-4 h-4 rounded-full absolute "
        }
      />
      <NodeToolbar>
        <Button color="error" onClick={handleDelete} children={"Delete"} />
        <Button
          onClick={handleDetach}
          children={"Detach"}
          disabled={!hasParent}
        />
      </NodeToolbar>
      <Handle type="target" position={Position.Top} />
      <div className="p-2 text-sm">{props.data.label}</div>
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
};

export default memo(DeviceNode);
