import { CircularProgress, Typography } from "@mui/material";

interface Props {
  variant: "global" | "local";
}

export const Loading = (props: Props) => {
  const loadingMessage = props.variant === "global" ? "Loading" : "";
  return (
    <div
      style={{
        position: props.variant === "global" ? "absolute" : "relative",
        left: "50%",
        top: "50%",
        WebkitTransform: "translate(-50%, -50%)",
        transform: "translate(-50%, -50%)",
      }}
      className="flex justify-center items-center gap-3"
    >
      <CircularProgress thickness={4} size={"1.75rem"} />
      <Typography variant="h5" color={"primary"} children={loadingMessage} />
    </div>
  );
};
