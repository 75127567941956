import { IconButton, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch } from "react-redux";
import { toggleNavBar } from "../features/navBarSlice";

interface Props {
  title: string;
  description?: string;
  element?: JSX.Element;
}

export const PageHeader = (props: Props) => {
  const dispatch = useDispatch();

  return (
    <div className="h-auto bg-dashboard w-full py-2 sticky top-0 z-[49]">
      <div className="min-h-20 card w-full px-4 sm:px-8 py-4 flex flex-col lg:flex-row gap-4 justify-between items-center">
        <div>
          <div className="flex items-center gap-2">
            <IconButton
              size="small"
              onClick={() => dispatch(toggleNavBar())}
              sx={{
                position: "absolute",
                left: "1rem",
                mb: 0.5,
                display: { xs: "initial", xl: "none" },
              }}
            >
              <MenuIcon sx={{ color: "black" }} fontSize="medium" />
            </IconButton>
            <h1 className="lg:ml-6 xl:ml-0 text-2xl">{props.title}</h1>
          </div>
          <Typography
            variant="body1"
            color={"text.secondary"}
            children={props.description}
          />
        </div>
        {props.element}
      </div>
    </div>
  );
};
