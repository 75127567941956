import { useEffect, useState } from "react";
import {
  getDisaggregated,
  getShortMeasurement,
} from "../../services/analyticsServiceAPI";
import { Loading } from "../../components/Loading";
import dayjs from "dayjs";
import ReactECharts from "echarts-for-react";
import { theme } from "../../assets/styles/theme";
import { PageHeader } from "../../components/PageHeader";
import { commonChartOptions } from "../../assets/config/commonChartOptions";
import { Interval } from "../../types/analyticsResponseTypes";

function getOption(
  title: string,
  actualData: [number, number][],
  predictedData?: [number, number][]
) {
  return {
    title: {
      text: title,
      ...commonChartOptions.title,
    },
    textStyle: commonChartOptions.textStyle,
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      valueFormatter: (value: number) => value.toFixed(1) + " Wh",
    },
    xAxis: {
      type: "time",
      axisLabel: {
        hideOverlap: true,
      },
    },
    yAxis: {
      type: "value",
      splitLine: {
        show: true,
      },
      name: "Wh",
    },
    legend: {
      ...commonChartOptions.legend,
    },
    animation: false,
    grid: {
      bottom: 0,
      left: 0,
      right: 0,
      containLabel: true,
    },
    series: [
      {
        name: "Actual Active Energy",
        type: "line",
        data: actualData,
        showSymbol: false,
        color: predictedData
          ? theme.palette.primary.dark
          : theme.palette.primary.light,
      },
      predictedData
        ? {
            name: "Predicted Active Energy",
            type: "line",
            data: predictedData,
            lineStyle: {
              type: "dotted",
            },
            showSymbol: false,
            color: theme.palette.secondary.main,
          }
        : "",
    ],
  };
}

const MAINS_METER_ID = 9000;
const INTERVAL: Interval = "s15";

export const Disaggregation = () => {
  const [predictedData, setPredictedData] = useState<{
    [id: string]: [number, number][];
  }>({});
  const [actualData, setActualData] = useState<{
    [id: string]: [number, number][];
  }>({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let controller = new AbortController();
    let mounted = true;
    fetchData();
    async function fetchData() {
      setIsLoading(true);
      const endDate = dayjs(); // now
      const startDate = endDate.subtract(6, "hour");
      try {
        const disaggregatedData = await getDisaggregated(
          startDate.unix(),
          endDate.unix(),
          MAINS_METER_ID,
          "dt",
          INTERVAL,
          controller.signal
        );
        const t2Ids = Object.keys(disaggregatedData);
        // make requests firstly for t2s then for the mains meter in order.
        const measurementsData = await Promise.all([
          ...t2Ids.map((t2Id) =>
            getShortMeasurement(
              startDate.unix(),
              endDate.unix(),
              +t2Id,
              INTERVAL,
              undefined,
              controller.signal
            )
          ),
          getShortMeasurement(
            startDate.unix(),
            endDate.unix(),
            MAINS_METER_ID,
            INTERVAL,
            undefined,
            controller.signal
          ),
        ]);
        let actual: typeof actualData = {};
        let predicted: typeof predictedData = {};
        t2Ids.forEach((t2Id, t2Index) => {
          predicted[t2Id] = Object.entries(disaggregatedData[t2Id]).map((d) => [
            +d[0] * 1000, // sec -> msec
            d[1] / 1000, // mWh -> Wh
          ]);
          actual[t2Id] = measurementsData[t2Index].map((m) => [
            m.calc_time * 1000, // sec -> msec
            m.lines.L1!.window_active_energy / 1000, // mWh -> Wh
          ]);
        });
        actual[String(MAINS_METER_ID)] = measurementsData[
          measurementsData.length - 1
        ].map((m) => [
          m.calc_time * 1000, // sec -> msec
          Object.values(m.lines)
            .map((params) => params.window_active_energy)
            .reduce((a, b) => a + b, 0) / 1000, // mWh -> Wh
        ]);
        if (mounted) {
          setActualData(actual);
          setPredictedData(predicted);
          setIsLoading(false);
        }
      } catch (error) {
        if (mounted) throw error;
      }
    }
    return () => {
      mounted = false;
      controller.abort();
    };
  }, []);

  if (isLoading) return <Loading variant="global" />;

  return (
    <div className="flex flex-col gap-2 w-full">
      <PageHeader
        title="Disaggregation"
        description="E-Probe Energy Disaggregation Visualization"
      />
      <div className="flex w-full xl:px-[5%]">
        <div className="card p-4 sm:p-8 h-72 sm:h-[28rem] block w-full">
          <ReactECharts
            style={{ width: "100%", height: "100%" }}
            option={getOption(
              `Mains meter - ${MAINS_METER_ID} (Aggregated)`,
              actualData[MAINS_METER_ID]
            )}
          />
        </div>
      </div>
      {Object.keys(predictedData).map((id) => (
        <div key={id} className="flex w-full xl:px-[5%]">
          <div className="card p-4 sm:p-8 h-72 sm:h-[28rem] block w-full overflow-hidden">
            <ReactECharts
              style={{ width: "100%", height: "100%" }}
              option={getOption(
                `Submeter - ${id}`,
                actualData[id],
                predictedData[id]
              )}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
