import {
  CircuitEnergyConsumptionAPIResponse,
  Line,
  MeasurementsAPIResponse,
  Parameters,
} from "../types/analyticsResponseTypes";
import dayjs from "dayjs";
import {
  deepCopy,
  getHarmonicByIndex,
  intervalToSeconds,
  isInHourlyRange,
} from "./otherUtils";
import { getComponentById } from "./componentUtils";
import { similarColors } from "../assets/styles/theme";
import measCfg from "../assets/config/measurementConfigs.json";

/**
 * Takes the api response of "epanalytics/getCircuitEnergyConsumption" endpoint,
 * processes that data for the "CircuitEnergyConsumption" sunburst and treemap
 * charts and returns that produced chart data.
 * @param responseData response data of the
 * "epanalytics/getCircuitEnergyConsumption" endpoint
 * @returns data series for the "CircuitEnergyConsumption" charts.
 */
export function prepareCircuitEnergyConsumptionChartData(
  responseData: CircuitEnergyConsumptionAPIResponse
) {
  // For both sunburst and treemap charts
  type ChartData = {
    name: string;
    value: number;
    children: ChartData[];
    itemStyle: {
      color: string;
    };
  };
  type DataType = "active_energy" | "reactive_energy" | "apparent_energy";
  let groupColorPairs: { [groupId: string]: string } = {};
  let colorIndex = 0;
  return {
    activeEnergyData: recursiveHelper(responseData, "active_energy"),
    reactiveEnergyData: recursiveHelper(responseData, "reactive_energy"),
    apparentEnergyData: recursiveHelper(responseData, "apparent_energy"),
  };

  // Prepares chart data for a dataType. Calls itself for each child.
  function recursiveHelper(
    responseData: CircuitEnergyConsumptionAPIResponse,
    dataType: DataType
  ) {
    const chartData: ChartData[] = [];
    for (const data of responseData) {
      const parentId = getComponentById(data.meter_id)!.parentGroupId!;
      if (!groupColorPairs[parentId])
        groupColorPairs[parentId] =
          colorIndex >= similarColors.length
            ? genRandomColor()
            : similarColors[colorIndex++];
      chartData.push({
        name: getComponentById(data.meter_id)?.name ?? String(data.meter_id),
        value:
          Object.values(data.lines).reduce(
            (sum, params) => sum + params[dataType],
            0
          ) / 1e3,
        children: recursiveHelper(data.children, dataType),
        itemStyle: {
          color: groupColorPairs[parentId],
        },
      });
    }
    return chartData;
  }
  function genRandomColor() {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgb(${r},${g},${b})`;
  }
}

/**
 * Takes the api response of measurements endpoints (virtual, short...),
 * processes that data for the "measurements" chart and returns that produced
 * chart data.
 * @param responseData response data of the measurements endpoints
 * @returns data series for the "measurements" chart.
 */
export function prepareMeasurementsChartData(
  responseData: MeasurementsAPIResponse
) {
  const chartData = {
    activeEnergyData: new Array<[string, number]>(),
    reactiveEnergyData: new Array<[string, number]>(),
  };
  if (responseData.length !== 0) {
    for (const data of responseData) {
      let isoDate = dayjs.unix(data.calc_time).format();
      let activeEnergySum = 0;
      let reactiveEnergySum = 0;
      for (const lineParams of Object.values(data.lines)) {
        activeEnergySum += lineParams.window_active_energy;
        reactiveEnergySum += lineParams.window_reactive_energy;
      }
      chartData.activeEnergyData.push([isoDate, activeEnergySum / 1e3]);
      chartData.reactiveEnergyData.push([isoDate, reactiveEnergySum / 1e3]);
    }
  }
  return chartData;
}

/**
 * prepares data for the dynamic heatmap
 * @param responseData data that will be processed
 * @param xPartCount divided count of period
 * @param yPartCount divided count of y axis ???
 * @param period length of the xAxis as seconds. It is optional.
 */
export function prepareDynamicHeatmapData(
  responseData: MeasurementsAPIResponse,
  startTime: number,
  endTime: number,
  yPartCount: number,
  xPartCount: number,
  period?: number
): {
  xData: number[];
  yData: string[];
  serieData: [number, number, number][];
  maxValue: number;
} {
  if (responseData.length === 0)
    return { xData: [], yData: [], serieData: [], maxValue: 0 };
  const yPartInterval = (endTime - startTime) / yPartCount; // value of each part as seconds
  const xPartInterval = (period ?? yPartInterval) / xPartCount; // value of each part as seconds
  let yData = [startTime + yPartInterval]; // yData şimdilik aralıkların üst limitini tutsun. (epoch seconds)
  while (yData.length < yPartCount) {
    yData.push(yData[yData.length - 1] + yPartInterval);
  }
  let xData = [xPartInterval / 60];
  while (xData.length < xPartCount) {
    xData.push(xData[xData.length - 1] + xPartInterval / 60);
  }
  let serieData: [xIndex: number, yIndex: number, value: number][] = [];
  for (let x = 0; x < xData.length; x++) {
    for (let y = 0; y < yData.length; y++) {
      serieData.push([x, y, 0]);
    }
  }
  let yIndex = 0;
  let xIndex = 0;
  let measurementIndex = 0;
  let maxValue = 0;
  let currentTime = startTime + xPartInterval;
  while (measurementIndex < responseData.length) {
    const measurement = responseData[measurementIndex];
    if (currentTime >= measurement.calc_time) {
      if (measurement.calc_time > yData[yData.length - 1])
        yIndex = yData.length - 1;
      else yIndex = yData.findIndex((y) => measurement.calc_time <= y);
      let value = 0;
      for (const params of Object.values(measurement.lines))
        value += params.window_active_energy;
      value /= 1e6;
      let serieDataIndex = xIndex * yData.length + yIndex;
      serieData[serieDataIndex][2] += value;
      if (serieData[serieDataIndex][2] > maxValue)
        maxValue = serieData[serieDataIndex][2];
      measurementIndex++;
    } else {
      xIndex = (xIndex + 1) % xData.length;
      currentTime += xPartInterval;
    }
  }
  // edit yData representation
  let yDataAsDates = yData.map(
    (epoch) => `
      ${dayjs.unix(epoch).format("lll")}
      ${dayjs.unix(epoch - yPartInterval).format("lll")}
      `
  );
  return { xData, yData: yDataAsDates, serieData, maxValue };
}

/**
 * prepares data for the heatmap chart
 * @param measurements data that will be processed
 */
export function prepareCalendarHeatmapData(
  _measurements: MeasurementsAPIResponse
): {
  serieData: [number, number][];
  range: string[] | number;
  maxValue: number;
} {
  // TODO: Remove this timezone based correction when it handled in backend.
  const measurements = _measurements.map((m) => {
    let _m: typeof m = deepCopy(m);
    let offSet = dayjs.unix(_m.calc_time).utcOffset() * 60; // in seconds
    _m.calc_time -= offSet + 1;
    return _m;
  });
  if (measurements.length === 0)
    return { serieData: [], range: dayjs().get("year"), maxValue: 0 };
  let currentDate = dayjs.unix(measurements[0].calc_time); // update for each new day
  let dayIndex = 0; // increment for each new day
  let maxValue = 0;
  const serieData: [number, number][] = [[measurements[0].calc_time * 1000, 0]];
  for (const measurement of measurements) {
    let value = 0;
    for (const params of Object.values(measurement.lines))
      value += params.window_active_energy;
    value /= 1e6;
    // if still in the same day
    if (currentDate.isSame(dayjs.unix(measurement.calc_time), "day")) {
      serieData[dayIndex][1] += value;
      if (serieData[dayIndex][1] > maxValue) maxValue = serieData[dayIndex][1];
    }
    // if new day
    else {
      currentDate = dayjs.unix(measurement.calc_time);
      dayIndex++;
      serieData[dayIndex] = [measurement.calc_time * 1000, value];
    }
  }
  // calendar chart range
  let lastMeasDate = dayjs.unix(
    measurements[measurements.length - 1].calc_time
  );
  let numOfDaysInMonth = lastMeasDate.daysInMonth();
  const range = [
    dayjs.unix(measurements[0].calc_time).format("YYYY-MM-[01]"),
    lastMeasDate.format(`YYYY-MM-[${numOfDaysInMonth}]`),
  ];
  return { serieData, range, maxValue };
}

/**
 * Gets active/reactive/appearent energy data from the measurements, prepares
 * the rows and columns for the DataGrid component in Reports page based on
 * given parameters and returns it.
 */
export function prepareReportsTableRows(
  measurements: MeasurementsAPIResponse,
  interval: "hourly" | "daily" | "weekly",
  puant: {
    t1: { start: [number, number]; end: [number, number] };
    t2: { start: [number, number]; end: [number, number] };
    t3: { start: [number, number]; end: [number, number] };
  }
) {
  const rows: any[] = [];
  if (measurements.length === 0) return rows;

  let rowInterval: number; // as seconds
  switch (interval) {
    case "hourly":
      rowInterval = 3600; //60 * 60;
      break;
    case "daily":
      rowInterval = 86400; // 60 * 60 * 24;
      break;
    case "weekly":
      rowInterval = 604800; // 60 * 60 * 24 * 7;
      break;
  }
  let isEmptyRow = true;
  let peakDemand = Number.MIN_VALUE;
  let offPeakDemand = Number.MAX_VALUE;
  let rowTime = measurements[0].calc_time;
  let rowIndex = -1;
  initilazeNextRow();

  let measurementIndex = 0;
  while (measurementIndex < measurements.length) {
    const measurement = measurements[measurementIndex];
    // updates current row if measurement in the current row
    if (measurement.calc_time <= rowTime) {
      isEmptyRow = false;
      // gets values and inserts to the row
      let activeEnergy = 0;
      let reactiveEnergy = 0;
      let appearentEnergy = 0;
      let activePower = 0;
      for (const params of Object.values(measurement.lines)) {
        activeEnergy += params.window_active_energy;
        reactiveEnergy += params.window_reactive_energy;
        appearentEnergy += params.window_apparent_energy;
        activePower += params.active_power;
      }
      rows[rowIndex].t0active += activeEnergy;
      rows[rowIndex].t0reactive += reactiveEnergy;
      rows[rowIndex].t0appearent += appearentEnergy;
      // checks max/min demand
      if (activePower > peakDemand) peakDemand = activePower;
      if (activePower < offPeakDemand) offPeakDemand = activePower;
      // updates T1,T2,T3
      const mDate = dayjs.unix(measurement.calc_time);
      const mHour = mDate.get("hour");
      const mMinute = mDate.get("minute");
      let t: keyof typeof puant;
      for (t in puant) {
        if (isInHourlyRange([mHour, mMinute], puant[t].start, puant[t].end)) {
          rows[rowIndex][t + "active"] += activeEnergy;
          rows[rowIndex][t + "reactive"] += reactiveEnergy;
          rows[rowIndex][t + "appearent"] += appearentEnergy;
          break;
        }
      }
      measurementIndex++; // next measurement data
    }
    // if the measurement is not belong to the current row
    else {
      finalizeCurrentRow();
      initilazeNextRow();
    }
  }
  finalizeCurrentRow(); // for the last row
  return rows;

  /* ==================== Helper Functions ===================== */
  /**
   * If the current row has no measurement data, then adds "-" to the columns.
   * Else corrects the representation of the values of each column.
   */
  function finalizeCurrentRow() {
    const row = rows[rowIndex];
    if (isEmptyRow)
      for (const col in row) {
        if (!["id", "time"].includes(col)) row[col] = "-";
      }
    else {
      row.peakDemand = peakDemand;
      row.offPeakDemand = offPeakDemand;
      for (const col in row) {
        if (!["id", "time"].includes(col))
          row[col] = (row[col] / 1e6).toFixed(2);
      }
    }
  }
  /**
   * Increments rowTime (+rowInterval), rowIndex (+1) and sets the other
   * variables with default values before the creation of the next row. Then,
   * fills the row with initial values.
   */
  function initilazeNextRow() {
    rowTime += rowInterval;
    rowIndex++;
    isEmptyRow = true;
    peakDemand = Number.MIN_VALUE;
    offPeakDemand = Number.MAX_VALUE;
    rows[rowIndex] = {
      id: rowIndex,
      time: rowTime,
      t0active: 0,
      t1active: 0,
      t2active: 0,
      t3active: 0,
      t0reactive: 0,
      t1reactive: 0,
      t2reactive: 0,
      t3reactive: 0,
      t0appearent: 0,
      t1appearent: 0,
      t2appearent: 0,
      t3appearent: 0,
      peakDemand: 0,
      offPeakDemand: 0,
    };
  }
}

export function aggregateWindowEnergies(measurements: MeasurementsAPIResponse) {
  let activeEnergyData = 0;
  let reactiveEnergyData = 0;
  let appearentEnergyData = 0;
  for (const measurement of measurements) {
    for (const params of Object.values(measurement.lines)) {
      activeEnergyData += params.window_active_energy;
      reactiveEnergyData += params.window_reactive_energy;
      appearentEnergyData += params.window_apparent_energy;
    }
  }

  return { activeEnergyData, reactiveEnergyData, appearentEnergyData };
}

export function prepareDetailsChartsData(
  measurements: MeasurementsAPIResponse
) {
  if (measurements.length === 0) return null;
  const chartSeries: any = {};

  measurements.forEach((row, rowIndex) => {
    // Add frequency measurements
    if (rowIndex === 0)
      chartSeries.frequency = [
        [row.calc_time * 1e3, row.frequency[row.frequency.length - 1] / 1e3],
      ];
    else {
      const intervalTime = intervalToSeconds(row.interval);
      const unitTime = intervalTime / row.frequency.length;
      const initialTime = row.calc_time - intervalTime;
      row.frequency.forEach((value, freqIndex) => {
        chartSeries.frequency.push([
          (initialTime + (freqIndex + 1) * unitTime) * 1e3,
          value / 1e3,
        ]);
      });
    }
    // Add measurements which in lines
    for (const line in row.lines) {
      for (const measName in row.lines[line as Line]) {
        // Adds harmonic values
        if (measName.includes("_h_")) {
          if (!chartSeries[measName]) chartSeries[measName] = {};
          const values = row.lines[line as Line]![
            measName as keyof Parameters
          ] as number[];
          values.forEach((value, index) => {
            let hType = measName.split("_").pop() as "even" | "odd";
            let harmonic = getHarmonicByIndex(index, hType);
            if (!chartSeries[measName][harmonic])
              chartSeries[measName][harmonic] = {};
            if (!chartSeries[measName][harmonic][line])
              chartSeries[measName][harmonic][line] = [];
            chartSeries[measName][harmonic][line].push(
              // @ts-ignore
              [row.calc_time * 1e3, value / measCfg[measName].divide]
            );
          });
        }
        // Adds other measurements
        else {
          if (!(measName in chartSeries))
            chartSeries[measName] = { L1: [], L2: [], L3: [] };
          chartSeries[measName][line].push([
            row.calc_time * 1e3,
            (row.lines[line as Line]![measName as keyof Parameters] as number) / // @ts-ignore
              measCfg[measName].divide,
          ]);
        }
      }
    }
  });
  return chartSeries;
}

export function prepareDetailsReportRows(
  measurements: MeasurementsAPIResponse
): any[] {
  const rows: any[] = [];
  let rowCounter = 0;
  measurements.forEach((meas) => {
    const lineNumber = Object.keys(meas.lines).length;
    Object.keys(meas.lines).forEach((line, lineIdx) => {
      let measLine = meas.lines[line as Line]!!;
      let row: any = {
        id: ++rowCounter,
        calc_time: meas.calc_time,
        interval: meas.interval,
        frequency:
          meas.frequency.reduce((acc, curr) => acc + curr, 0) /
          meas.frequency.length,
        line: line,
        active_power: measLine.active_power,
        reactive_power: measLine.reactive_power,
        apparent_power: measLine.apparent_power,
        window_active_energy: measLine.window_active_energy,
        window_reactive_energy: measLine.window_reactive_energy,
        window_apparent_energy: measLine.window_apparent_energy,
        cumulative_active_energy: measLine.cumulative_active_energy,
        cumulative_reactive_energy: measLine.cumulative_reactive_energy,
        cumulative_apparent_energy: measLine.cumulative_apparent_energy,
        fi_angle: measLine.fi_angle,
        i_rms: measLine.i_rms,
        v_rms: measLine.v_rms,
        thdi: measLine.thdi,
        thdv: measLine.thdv,
        rowSpan:
          lineIdx === 0
            ? {
                calc_time: lineNumber,
                interval: lineNumber,
                frequency: lineNumber,
              }
            : null,
      };
      measLine.v_h_odd!.forEach((val, idx) => {
        row[`v_h${idx * 2 + 1}`] = val;
      });
      measLine.v_h_even!.forEach((val, idx) => {
        row[`v_h${idx * 2 + 2}`] = val;
      });
      measLine.i_h_odd!.forEach((val, idx) => {
        row[`i_h${idx * 2 + 1}`] = val;
      });
      measLine.i_h_even!.forEach((val, idx) => {
        row[`i_h${idx * 2 + 2}`] = val;
      });
      rows.push(row);
    });
  });
  return rows;
}
