import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import Flow from "./Flow/Flow";
import {
  ComponentProperties,
  GetValueAPIResponse,
} from "../../../types/ldmResponseTypes";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import CustomModal from "../../../components/CustomModal";
import AddGroupForm from "./Forms/AddGroupForm";
import AddDeviceForm from "./Forms/AddDeviceForm";
import { useState } from "react";
import { ReactFlowInstance } from "reactflow";

interface Props {
  onReset: () => void;
  onSave: () => void;
  isSaving: boolean;
  hasEditedProps: boolean;
  setEditedProps: React.Dispatch<
    React.SetStateAction<{
      [id: string]: ComponentProperties;
    }>
  >;
  initialProps: {
    [id: string]: GetValueAPIResponse;
  };
  setRfInstance: React.Dispatch<
    React.SetStateAction<ReactFlowInstance | undefined>
  >;
  hasDraggedNode: boolean;
  setHasDraggedNode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TopologyCard = (props: Props) => {
  const {
    hasEditedProps,
    isSaving,
    onReset,
    onSave,
    setEditedProps,
    initialProps,
    setRfInstance,
    hasDraggedNode,
    setHasDraggedNode,
  } = props;
  const { detachedNodeIds } = useSelector((state: RootState) => state.topology);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState<boolean>(false);
  const [isDeviceModalOpen, setIsDeviceModalOpen] = useState<boolean>(false);

  return (
    <div className="card p-0 h-full flex flex-col flex-grow overflow-hidden">
      {/* Panel */}
      <div className="p-4 flex gap-2 justify-between shadow-sm items-center">
        {/* left-side */}
        <div className="flex gap-2">
          <Button
            children={"Add Device"}
            disabled={false}
            onClick={() => {
              setIsDeviceModalOpen(true);
            }}
            size="small"
            variant="outlined"
          />

          <Button
            children={"Add Group"}
            disabled={false}
            onClick={() => {
              setIsGroupModalOpen(true);
            }}
            size="small"
            variant="outlined"
          />
        </div>
        {/* right-side */}
        <div>
          <Button
            children={"Reset"}
            disabled={!hasEditedProps && !hasDraggedNode}
            onClick={onReset}
            size="small"
            variant="text"
          />
          <LoadingButton
            children={<span>Save</span>}
            disabled={
              (!hasEditedProps && !hasDraggedNode) || detachedNodeIds.length > 0
            }
            onClick={onSave}
            size="small"
            variant="contained"
            loading={isSaving}
          />
        </div>
      </div>
      {/* Flow */}
      <div className="w-full lg:flex-1 h-[50vh] lg:h-full">
        <Flow
          setEditedProps={setEditedProps}
          initialProps={initialProps}
          setRfInstance={setRfInstance}
          setHasDraggedNode={setHasDraggedNode}
        />
      </div>
      <CustomModal
        title="Add Group"
        children={<AddGroupForm setIsGroupModalOpen={setIsGroupModalOpen} />}
        isOpen={isGroupModalOpen}
        setIsOpen={setIsGroupModalOpen}
      />

      <CustomModal
        title="Add Device"
        children={<AddDeviceForm setIsDeviceModalOpen={setIsDeviceModalOpen} />}
        isOpen={isDeviceModalOpen}
        setIsOpen={setIsDeviceModalOpen}
      />
    </div>
  );
};
