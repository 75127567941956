import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";

export const timeRangeOptions = [
  { id: 1, name: "Last 10 Minutes" },
  { id: 2, name: "Last 30 Minutes" },
  { id: 3, name: "Last 1 Hour" },
  { id: 4, name: "Last 2 Hours" },
  { id: 5, name: "Last 1 Day" },
  { id: 6, name: "Last 2 Days" },
  { id: 7, name: "Last 1 Week" },
  { id: 8, name: "Last 1 Month" },
  { id: 9, name: "Last 3 Months" },
  { id: 0, name: "Custom" },
];

export interface TimeRangeState {
  timeRangeOptionId: number;
  startTime: number;
  endTime: number;
}

/**
 * Last 10 minutes as initial time range.
 * If you want to change this, you should also change initial
 * selectedTimeRangeOption in TimeRange.tsx component to correct default
 * displayed timeRange option on selectbox.
 */
const initialState: TimeRangeState = {
  timeRangeOptionId: 1,
  startTime: dayjs().subtract(10, "minutes").unix(),
  endTime: dayjs().unix(),
};

export const timeRangeSlice = createSlice({
  name: "time-range-slice",
  initialState,
  reducers: {
    setTimeRange: (state, action: PayloadAction<[number, number]>) => {
      state.startTime = action.payload[0];
      state.endTime = action.payload[1];
      state.timeRangeOptionId = 0;
    },
    setTimeRangeOption: (state, action: PayloadAction<number>) => {
      const timeRangeOption = timeRangeOptions.find(
        (t) => t.id === action.payload
      );
      if (!timeRangeOption) {
        throw Error("Invalid time range option id!");
      }
      state.timeRangeOptionId = timeRangeOption.id;
      if (timeRangeOption.id !== 0) {
        const now = dayjs();
        const words = timeRangeOption.name.split(" ");
        state.endTime = now.unix();
        state.startTime = now
          .subtract(
            Number(words[1]),
            words[2].toLowerCase() as dayjs.ManipulateType
          )
          .unix();
      }
    },
  },
});

export const { setTimeRange, setTimeRangeOption } = timeRangeSlice.actions;
export default timeRangeSlice.reducer;
