import ReactECharts from "echarts-for-react";
import MeasurementsChart from "../../components/charts/MeasurementsChart";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  prepareCircuitEnergyConsumptionChartData,
  prepareMeasurementsChartData,
} from "../../utils/chartUtils";
import CircuitEnergyConsumptionChart from "../../components/charts/CircuitEnergyConsumptionChart";
import { commonChartOptions } from "../../assets/config/commonChartOptions";

interface Props {
  isFetchingConsumptions: boolean;
  isFetchingMeasurements: boolean;
}

export const MonitoringChartCards = (props: Props) => {
  /**
   * m: measurements
   * cec: circuit energy consumption
   */
  const mChartRef = useRef<ReactECharts>(null);
  const cecChartRef = useRef<ReactECharts>(null);
  const mData = useSelector((state: RootState) => state.measurements.data);
  const cecData = useSelector(
    (state: RootState) => state.circuitEnergyConsumption.data
  );

  // for consumption pie chart
  useEffect(() => {
    const cecChartInstance = cecChartRef.current!.getEchartsInstance();
    // mData and cecData global states will be updated after the fetching process.
    if (props.isFetchingConsumptions) {
      cecChartInstance.showLoading(commonChartOptions.loadSpinner);
    }
    // Updates monitoring charts when the fetching process completed with fetched data.
    else {
      const cecChartData = prepareCircuitEnergyConsumptionChartData(cecData);
      const isNoData = Object.values(cecChartData).every((d) => !d.length);
      cecChartInstance.setOption({
        title: { id: "noData", show: isNoData },
        series: [{ data: cecChartData.activeEnergyData }],
      });
      cecChartInstance.hideLoading();
    }
  }, [props.isFetchingConsumptions]); // eslint-disable-line

  // for measurement chart
  useEffect(() => {
    const mChartInstance = mChartRef.current!.getEchartsInstance();
    if (props.isFetchingMeasurements) {
      mChartInstance.showLoading(commonChartOptions.loadSpinner);
    }
    // Updates measurement chart when the fetching process completed with fetched data.
    else {
      const mChartData = prepareMeasurementsChartData(mData);
      const isNoData = Object.values(mChartData).every((d) => !d.length);
      mChartInstance.setOption({
        title: { id: "noData", show: isNoData },
        legend: { show: !isNoData },
        series: [
          {
            name: "Active Energy",
            data: mChartData.activeEnergyData,
            showSymbol: false,
          },
          {
            name: "Reactive Energy",
            data: mChartData.reactiveEnergyData,
            showSymbol: false,
          },
        ],
      });
      mChartInstance.hideLoading();
    }
  }, [props.isFetchingMeasurements]); // eslint-disable-line

  return (
    <div className="flex flex-col gap-1 w-full">
      <div className="card h-72 sm:h-[30rem] p-4 sm:p-8">
        <MeasurementsChart chartRef={mChartRef} />
      </div>
      <div className="card h-72 sm:h-[30rem] p-4 sm:p-8">
        <CircuitEnergyConsumptionChart chartRef={cecChartRef} />
      </div>
    </div>
  );
};
