import { useEffect, useState } from "react";
import ComponentTreeSelect from "../../components/ComponentTreeSelect";
import { PageHeader } from "../../components/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import MonitoringInfoCards from "../Monitoring/MonitoringInfoCards";
import DetailsChartCards from "./DetailsChartCards";
import TimeRange from "../../components/TimeRange/TimeRange";
import { getMeasurement } from "../../services/analyticsServiceAPI";
import {
  getComponentById,
  getComponentsList,
} from "../../utils/componentUtils";
import { setComponentID } from "../../features/componentSlice";
import { MeasurementsAPIResponse } from "../../types/analyticsResponseTypes";

const Details = () => {
  const dispatch = useDispatch();
  const componentId = useSelector((state: RootState) => state.component.id!);
  const [measurements, setMeasurements] = useState<MeasurementsAPIResponse>([]);
  const { startTime, endTime } = useSelector(
    (state: RootState) => state.timeRange
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState({ state: false, message: "" });

  useEffect(() => {
    let mounted = true;
    let controller = new AbortController();
    setIsLoading(true);

    if (getComponentById(componentId)!.type !== "group") fetch();
    else {
      const firstFoundDevice = getComponentsList().find(
        (c) => c.type !== "group"
      );
      if (!firstFoundDevice)
        setIsError({
          state: true,
          message: "There is not exist any device component!",
        });
      else dispatch(setComponentID(firstFoundDevice.id));
    }
    // on dismount component
    return () => {
      mounted = false;
      controller.abort();
    };

    async function fetch() {
      try {
        const mData = await getMeasurement(
          startTime,
          endTime,
          componentId,
          undefined,
          undefined,
          controller.signal
        );
        if (mounted) setMeasurements(mData);
      } catch (error) {
        if (mounted) console.log(error);
      } finally {
        if (mounted) setIsLoading(false);
      }
    }
  }, [componentId, startTime, endTime]); // eslint-disable-line

  return (
    <div className="grid lg:grid-cols-12 gap-2">
      <div className="lg:col-span-9">
        <PageHeader
          title="Details"
          element={
            <div className="flex gap-2">
              <ComponentTreeSelect optionsType="device" />
              <TimeRange />
            </div>
          }
        />
        {isError.state ? (
          <p>{isError.message}</p>
        ) : (
          <DetailsChartCards
            isLoading={isLoading}
            measurements={measurements}
          />
        )}
      </div>
      <div className="lg:col-span-3">
        <MonitoringInfoCards componentId={componentId} />
      </div>
    </div>
  );
};

export default Details;
