import { IconButton } from "@mui/material";
import { BaseEdge, EdgeProps, getBezierPath, useReactFlow } from "reactflow";
import { Clear } from "@mui/icons-material/";

const foreignObjectSize = 40;

const ButtonEdge = (props: EdgeProps) => {
  const { sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition } =
    props;
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const reactFlowInstance = useReactFlow();

  const handleDelete = () => {
    reactFlowInstance.deleteElements({
      edges: [reactFlowInstance.getEdge(props.id)!],
    });
  };

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={props.markerEnd} />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={labelX - foreignObjectSize / 2}
        y={labelY - foreignObjectSize / 2}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <div hidden={!props.selected} className="edgebutton">
          <IconButton
            className="edgebutton"
            children={<Clear />}
            size="small"
            color="error"
            onClick={handleDelete}
          />
        </div>
      </foreignObject>
    </>
  );
};

export default ButtonEdge;
