import { useEffect, useState } from "react";
import { TopologySidebar } from "./TopologySidebar/TopologySidebar";
import { Loading } from "../../components/Loading";
import { getPropertiesOfAll } from "../../services/compositeRequests";
import { GetValueAPIResponse } from "../../types/ldmResponseTypes";
import { deviceConnectionStatus, setValue } from "../../services/ldmServiceAPI";
import { useNavigate } from "react-router";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  setComponentStructure,
  setConnectionStatus,
} from "../../features/componentStructureSlice";
import {
  getComponentsList,
  hasAnyParentlessT2,
} from "../../utils/componentUtils";
import { Toast } from "../../components/Toast";
import { wait, ParentlessDeviceError, deepCopy } from "../../utils/otherUtils";
import { TopologyCard } from "./TopologyCard/TopologyCard";
import {
  clearDetachedNodeIds,
  setComponentStructureForFlow,
} from "../../features/topologySlice";
import { PageHeader } from "../../components/PageHeader";
import { ReactFlowInstance } from "reactflow";
import { encryptData } from "../../utils/cryptoUtils";

const Topology = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorToast, setErrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>(
    "An error occured while saving the changes!"
  );
  const [successToast, setSuccessToast] = useState(false);
  const [initialProps, setInitialProps] = useState<{
    [id: string]: GetValueAPIResponse;
  }>({});
  const [editedProps, setEditedProps] = useState<{
    [id: string]: GetValueAPIResponse["editable"];
  }>({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [hasDraggedNode, setHasDraggedNode] = useState(false);
  const { componentStructureForFlow } = useSelector(
    (state: RootState) => state.topology
  );
  const componentStructure = useSelector(
    (state: RootState) => state.componentStructure.structure
  );
  const [rfInstance, setRfInstance] = useState<ReactFlowInstance>();

  useEffect(() => {
    fetchData();
    async function fetchData() {
      // TODO: Errors should be handled!
      setIsLoading(true);
      setInitialProps(await getPropertiesOfAll());
      let componentIdsList = getComponentsList()
        .filter((c) => c.type !== "group")
        .map((c) => c.id);
      dispatch(
        setConnectionStatus(await deviceConnectionStatus(componentIdsList))
      );
      setIsLoading(false);
    }
  }, []); // eslint-disable-line

  const onSave = async () => {
    setIsSaving(true);
    setErrorToast(false);
    setSuccessToast(false);
    try {
      if (hasAnyParentlessT2(deepCopy(componentStructureForFlow))) {
        throw new ParentlessDeviceError(
          "Parentless T2 device component found! Please attach it before saving."
        );
      }

      for (const id in editedProps) {
        await setValue(Number(id), editedProps[id], true);
      }
      setSuccessToast(true);
      let currentProps: typeof initialProps = {};
      for (const id of Object.keys(initialProps)) {
        currentProps[id] = {
          editable: {
            ...initialProps[id].editable,
            ...(editedProps[id] ?? {}),
          },
          not_editable: initialProps[id].not_editable,
        };
      }
      setInitialProps(currentProps);
      dispatch(setComponentStructure(componentStructureForFlow));
      setEditedProps({});
      setHasDraggedNode(false);
      setIsSaving(false);
      if (rfInstance)
        localStorage.setItem("rfInstance", encryptData(rfInstance.toObject())); // Save flow layout
    } catch (error) {
      // if the error is ParentlessDeviceError, show the error message and do not reload the page
      // otherwise, reload the page after 1.8 seconds
      if (error instanceof ParentlessDeviceError) {
        setErrorMessage(error.message);
        setIsSaving(false);
        console.error(error.message);
      } else {
        console.error(error);
        await wait(1800);
        navigate(0); // reloads page
      }
      setErrorToast(true);
    }
  };

  const onReset = () => {
    setEditedProps({});
    setHasDraggedNode(false);
    dispatch(setComponentStructureForFlow(deepCopy(componentStructure)));
    dispatch(clearDetachedNodeIds());
  };

  if (isLoading) return <Loading variant="global" />;

  return (
    <div className="flex flex-col h-auto">
      <PageHeader title="Topology" />
      <div className="h-auto lg:h-[calc(100vh-5.75rem)] flex flex-col lg:flex-row gap-2">
        <TopologyCard
          isSaving={isSaving}
          hasEditedProps={Object.keys(editedProps).length > 0}
          onSave={onSave}
          onReset={onReset}
          setEditedProps={setEditedProps}
          initialProps={initialProps}
          setRfInstance={setRfInstance}
          hasDraggedNode={hasDraggedNode}
          setHasDraggedNode={setHasDraggedNode}
        />
        <TopologySidebar
          initialProps={initialProps}
          editedProps={editedProps}
          setEditedProps={setEditedProps}
        />
      </div>
      <Toast
        variant="success"
        message="Changes has been saved succesfully!"
        open={successToast}
        setOpen={setSuccessToast}
      />
      <Toast
        variant="error"
        message={errorMessage}
        open={errorToast}
        setOpen={setErrorToast}
      />
    </div>
  );
};

export default Topology;
