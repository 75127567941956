import { Card, CardContent, Link, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { getApiVersions } from "../../../services/compositeRequests";

const Footer = () => {
  const clickCounter = useRef(0);
  const [isInfoCardHidden, setInfoCardHidden] = useState(true);
  const [apiVersions, setVersions] = useState([]);

  useEffect(() => {
    getApiVersions().then((res) => {
      setVersions(res);
    });
  }, []);

  const handleClick = () => {
    if (isInfoCardHidden === false) {
      setInfoCardHidden(true);
      return;
    }
    clickCounter.current++;
    if (clickCounter.current % 5 === 0) setInfoCardHidden(false);
  };

  return (
    <div className="fixed bottom-1 left-1/2 transform -translate-x-1/2 z-50">
      <Card
        variant="outlined"
        hidden={isInfoCardHidden}
        className="h-auto w-fit place-self-center mb-2"
        sx={{
          opacity: 0.7,
          backgroundColor: "Background",
          borderRadius: "0.75rem",
          boxShadow: 6,
        }}
      >
        <CardContent>
          <p>epdashboard: {process.env.REACT_APP_FRONTEND_VERSION}</p>
          {apiVersions.map((version: [string, string]) => (
            <p key={version[0]}>
              {version[0]}: {version[1]}
            </p>
          ))}
        </CardContent>
      </Card>
      <Typography variant="body2" color="text.secondary" align="center">
        {new Date().getFullYear()}{" "}
        <button onClick={handleClick}> &copy; </button>{" "}
        <Link
          target="_blank"
          color="inherit"
          href={process.env.REACT_APP_LANDING_PAGE_URL}
          children="Linarit"
        />
      </Typography>
    </div>
  );
};

export default Footer;
