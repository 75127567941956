import { Typography } from "@mui/material";
import { getComponentById } from "../../utils/componentUtils";
import { useMemo } from "react";
import { prepareCircuitEnergyConsumptionChartData } from "../../utils/chartUtils";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface IProps {
  componentId: number;
}

const MonitoringInfoCards = (props: IProps) => {
  const component = useMemo(
    () => getComponentById(props.componentId),
    [props.componentId]
  )!;

  const cecData = useSelector(
    (state: RootState) => state.circuitEnergyConsumption.data
  );

  function getLabel(componentId: number) {
    const comp = getComponentById(componentId)!;
    return comp.name ?? comp.id;
  }

  return (
    <div
      className={
        " flex gap-1 " +
        " relative flex-col md:flex-row lg:flex-col" +
        " lg:sticky lg:top-2 lg:right-0 lg:h-[calc(100vh-1rem)] "
      }
    >
      <div className="flex flex-col gap-1">
        <div className="card p-4 sm:p-8">
          <div className="card-header">Component Details</div>
          <div className="flex flex-col text-sm">
            <p className="flex gap-2">
              <span className="text-gray-500">Name:</span>
              <span className="">{component.name}</span>
            </p>
            <p className="flex gap-2">
              <span className="text-gray-500">Type:</span>
              <span className="">{component.type}</span>
            </p>
            <p className="flex gap-2">
              <span className="text-gray-500">Group:</span>
              <span className="">
                {component.parentGroupId
                  ? getLabel(component.parentGroupId)
                  : null}
              </span>
            </p>
            <p className="flex gap-2">
              <span className="text-gray-500">Total Consumption:</span>
              <span className="">
                {cecData &&
                  cecData.length > 0 &&
                  prepareCircuitEnergyConsumptionChartData(cecData)
                    .activeEnergyData[0].value + " Wh"}
              </span>
            </p>
          </div>
        </div>

        <div className="card p-4 sm:p-8">
          <div className="card-header">Estimated Cost</div>
          <Typography variant="body2" children="Active:" />
          <Typography variant="body2" children="Reactive:" />
          <Typography variant="body2" children="Total:" />
        </div>
      </div>

      <div className="card p-4 sm:p-8 flex-grow-[1]">
        <div className="card-header">Notifications</div>
      </div>
    </div>
  );
};

export default MonitoringInfoCards;
