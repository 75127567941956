import { Button } from "@mui/material";
import { NodeToolbar, ReactFlowState, useReactFlow, useStore } from "reactflow";

const selectedNodesSelector = (state: ReactFlowState) =>
  Array.from(state.nodeInternals.values()).filter((node) => node.selected);

const MultiSelectionToolbar = () => {
  const reactFlowInstance = useReactFlow();
  const selectedNodes = useStore(selectedNodesSelector);
  const isVisible = selectedNodes.length > 1;

  const handleDelete = () => {
    reactFlowInstance.deleteElements({
      nodes: selectedNodes,
    });
  };

  return (
    <NodeToolbar
      nodeId={selectedNodes.map((node) => node.id)}
      isVisible={isVisible}
    >
      <Button color="error" children={"delete"} onClick={handleDelete} />
    </NodeToolbar>
  );
};

export default MultiSelectionToolbar;
