import { Navigate, Outlet } from "react-router-dom";

/**
 * This is an extended Route component. To make authentication mandatory for a
 * user to access a page you should add that page component inside of this
 * component.
 */
const PrivateRoute = () => {
  const isAuth = localStorage.getItem("access-token") ? true : false;

  return isAuth ? <Outlet /> : <Navigate replace to="/login" />;
};

export default PrivateRoute;
