import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MeasurementsAPIResponse } from "../types/analyticsResponseTypes";

export interface MeasurementsState {
  data: MeasurementsAPIResponse;
}

const initialState: MeasurementsState = {
  data: [],
};

export const measurementsSlice = createSlice({
  name: "measurements-slice",
  initialState,
  reducers: {
    setMeasurementsData(state, action: PayloadAction<MeasurementsAPIResponse>) {
      state.data = action.payload;
    },
  },
});

export const { setMeasurementsData } = measurementsSlice.actions;
export default measurementsSlice.reducer;
