import { baseClient, updateAccessClient } from "./axiosConfig";
import {
  AuthAccessUpdateAPIResponse,
  AuthLoginAPIResponse,
} from "../types/iamResponseTypes";
import { AxiosResponse } from "axios";

export async function login(
  email: string,
  password: string
): Promise<AuthLoginAPIResponse> {
  const { data } = await baseClient.post(
    process.env.REACT_APP_URL_AUTH_LOGIN!,
    { email, password }
  );
  return data;
}

export async function updateAccess(
  refresh_token: string
): Promise<AxiosResponse<AuthAccessUpdateAPIResponse>> {
  return await updateAccessClient.get(
    process.env.REACT_APP_URL_AUTH_ACCESS_UPDATE!,
    {
      headers: { Authorization: refresh_token },
    }
  );
}
