import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CircuitEnergyConsumptionAPIResponse } from "../types/analyticsResponseTypes";

export interface CircuitEnergyConsumptionState {
  data: CircuitEnergyConsumptionAPIResponse;
}

const initialState: CircuitEnergyConsumptionState = {
  data: [],
};

export const circuitEnergyConsumptionSlice = createSlice({
  name: "circuit-energy-consumption-slice",
  initialState,
  reducers: {
    setCircuitEnergyConsumptionData(
      state,
      action: PayloadAction<CircuitEnergyConsumptionAPIResponse>
    ) {
      state.data = action.payload;
    },
  },
});

export const { setCircuitEnergyConsumptionData } =
  circuitEnergyConsumptionSlice.actions;
export default circuitEnergyConsumptionSlice.reducer;
