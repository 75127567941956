import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { setTimeRange } from "../../features/timeRangeSlice";
import { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";

interface IProps {
  isDialogOpen: boolean;
  setDialogOpen: (isDialogOpen: boolean) => void;
}

/**
 * This component will be displayed if "isDialogOpen" state is true.
 * "isDialogOpen" state becomes true when the user selected "custom" time
 * range option.
 *
 * We have pickerTimes and activeTimes and they are different kind of things.
 * - pickerTimes can accept any value, even null. Because they will be used for
 * just current situation of the pickers.
 * - activeTimes, however, corresponds to global states which represents
 * selected timeRange for the app. They will be setted to pickerTimes if
 * pickerTimes are valid values.
 */
const TimeRangeDialog = ({ isDialogOpen, setDialogOpen }: IProps) => {
  const dispatch = useDispatch();
  const activeStartTime = useSelector(
    (state: RootState) => state.timeRange.startTime
  );
  const activeEndTime = useSelector(
    (state: RootState) => state.timeRange.endTime
  );
  const [pickerStartTime, setPickerStartTime] = useState<Dayjs | null>(
    dayjs.unix(activeStartTime)
  );
  const [pickerEndTime, setPickerEndTime] = useState<Dayjs | null>(
    dayjs.unix(activeEndTime)
  );

  const handleApplyButtonClick = () => {
    dispatch(setTimeRange([pickerStartTime!.unix(), pickerEndTime!.unix()]));
    setDialogOpen(false);
  };

  // When dialog displayed on the screen
  useEffect(() => {
    if (isDialogOpen) {
      setPickerStartTime(dayjs.unix(activeStartTime));
      setPickerEndTime(dayjs.unix(activeEndTime));
    }
  }, [isDialogOpen]); // eslint-disable-line

  return (
    <Dialog
      keepMounted={false}
      onClose={() => setDialogOpen(false)}
      open={isDialogOpen}
    >
      <DialogTitle>
        Custom Time Range
        <IconButton
          aria-label="close"
          onClick={() => setDialogOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <div className="grid grid-cols-1 gap-4 sm:gap-2 sm:grid-cols-2 mx-4 my-2">
        <DateTimePicker
          label={"Start Time"}
          renderInput={(props) => <TextField {...props} />}
          value={pickerStartTime}
          onChange={(newDate) => {
            setPickerStartTime(newDate);
          }}
          maxDateTime={pickerEndTime}
          ampm={false}
        />
        <DateTimePicker
          label={"End Time"}
          renderInput={(props) => <TextField {...props} />}
          value={pickerEndTime}
          onChange={(newDate) => {
            setPickerEndTime(newDate);
          }}
          maxDateTime={dayjs()}
          minDateTime={pickerStartTime}
          ampm={false}
        />
      </div>

      <DialogActions>
        <Button
          onClick={handleApplyButtonClick}
          sx={{ mr: 1 }}
          disabled={
            pickerStartTime === null ||
            pickerEndTime === null ||
            !pickerStartTime!.isValid() ||
            !pickerEndTime!.isValid() ||
            pickerStartTime > pickerEndTime ||
            pickerEndTime > dayjs()
          }
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimeRangeDialog;
