import ReactECharts from "echarts-for-react";
import { memo } from "react";
import { commonChartOptions } from "../../assets/config/commonChartOptions";
import dayjs from "dayjs";
import ReactDOMServer from "react-dom/server";
import measurementCfg from "../../assets/config/measurementConfigs.json";

interface IProps {
  title: string;
  chartRef: React.RefObject<ReactECharts>;
}

const DetailsChart = (props: IProps) => {
  const option = {
    title: [
      commonChartOptions.noDataTitle,
      { ...commonChartOptions.title, text: props.title },
    ],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      valueFormatter: (value: number) =>
        // @ts-ignore
        `${value.toFixed(2)} ${measurementCfg[props.title].unit}`,
    },
    animation: false,
    xAxis: {
      type: "time",
      axisLabel: {
        hideOverlap: true,
      },
    },
    yAxis: {
      type: "value",
      splitLine: {
        show: true,
      },
    },
    toolbox: {
      ...commonChartOptions.toolbox,
      feature: {
        mark: { show: true },
        magicType: { show: true, type: ["bar", "line"] },
        dataView: {
          show: true,
          readOnly: true,
          optionToContent: (option: any) => {
            // returns the HTML convertion of JSX element
            return ReactDOMServer.renderToStaticMarkup(
              <table className="text-center w-11/12 m-auto">
                <tbody>
                  <tr className="border-b-[1px] border-black">
                    <th>Time</th>
                    {option.series.map((serie: any) => (
                      <th key={serie.name}> {serie.name}</th>
                    ))}
                  </tr>
                  {option.series[0].data.map(
                    (data: [string, number], index: number) => (
                      <tr className="border-b-[1px] border-black" key={index}>
                        <td>{dayjs(data[0]).format("L LT")}</td>
                        <td>{option.series[0].data[index][1]}</td>
                        <td>{option.series[1].data[index][1]}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            );
          },
        },
        saveAsImage: { show: true },
      },
    },
    legend: {
      ...commonChartOptions.legend,
    },
    grid: {
      bottom: 0,
      left: 0,
      right: 0,
      containLabel: true,
    },
    series: [],
    color: commonChartOptions.color,
    textStyle: commonChartOptions.textStyle,
  };

  return (
    <ReactECharts
      style={{ width: "100%", height: "100%" }}
      ref={props.chartRef}
      option={option}
    />
  );
};

export default memo(DetailsChart);
