import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import ReactECharts from "echarts-for-react";
import { RootState } from "../../store";
import { commonChartOptions } from "../../assets/config/commonChartOptions";
import { getComponentById } from "../../utils/componentUtils";
import {
  getShortMeasurement,
  getVirtualMeasurement,
} from "../../services/analyticsServiceAPI";
import dayjs from "dayjs";
import { prepareCalendarHeatmapData } from "../../utils/chartUtils";
import { MeasurementsAPIResponse } from "../../types/analyticsResponseTypes";
import CalendarHeatmap from "../../components/charts/CalendarHeatmap";

const CalendarHeatmapCard = () => {
  const componentId = useSelector((state: RootState) => state.component.id!);
  const calendarHeatmapRef = useRef<ReactECharts>(null);

  useEffect(() => {
    let mounted = true;
    let controller = new AbortController();
    fetch();
    async function fetch() {
      setIsLoading(true);
      const endTime = dayjs().unix(); // now
      const startTime = dayjs // 4 months ago
        .unix(endTime)
        .subtract(3, "months")
        .set("date", 1)
        .unix();
      try {
        const measurements =
          getComponentById(componentId)!.type === "group"
            ? await getVirtualMeasurement(
                startTime,
                endTime,
                componentId,
                "auto",
                undefined,
                controller.signal
              )
            : await getShortMeasurement(
                startTime,
                endTime,
                componentId,
                "auto",
                undefined,
                controller.signal
              );
        if (mounted) {
          updateCalendarHeatmap(measurements, [startTime * 1e3, endTime * 1e3]);
          setIsLoading(false);
        }
      } catch (error) {
        if (mounted) {
          console.error(error);
          setIsLoading(false);
        }
      }
    }
    return () => {
      mounted = false;
      controller.abort();
    };
  }, [componentId]);

  function setIsLoading(isShow: boolean) {
    const calendarHeatmapInstance =
      calendarHeatmapRef.current!.getEchartsInstance();
    if (isShow)
      calendarHeatmapInstance.showLoading(commonChartOptions.loadSpinner);
    else calendarHeatmapInstance.hideLoading();
  }

  function updateCalendarHeatmap(
    measurements: MeasurementsAPIResponse,
    range: [number, number]
  ) {
    const calendarHeatmapData = prepareCalendarHeatmapData(measurements);
    const isNoData = !calendarHeatmapData.serieData.length;
    calendarHeatmapRef.current!.getEchartsInstance().setOption({
      title: {
        id: "noData",
        show: isNoData,
      },
      series: [
        {
          name: "Active Energy",
          data: calendarHeatmapData.serieData.map((d) => [
            d[0],
            d[1].toFixed(1),
          ]),
        },
      ],
      calendar: {
        // range: calendarHeatmapData.range,
        range: range,
      },
      visualMap: {
        max: calendarHeatmapData.maxValue,
        show: !isNoData,
      },
    });
  }

  return (
    <div className="card p-4 sm:p-8 h-80 sm:h-[30rem] flex w-full lg:w-1/2">
      <CalendarHeatmap chartRef={calendarHeatmapRef} />
    </div>
  );
};

export default CalendarHeatmapCard;
