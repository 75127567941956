import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeviceComponent, GroupComponent } from "../types/componentTypes";
import { DeviceConnectionStatusAPIResponse } from "../types/ldmResponseTypes";
import { deepCopy } from "../utils/otherUtils";

export interface ComponentStructureState {
  structure: (DeviceComponent | GroupComponent)[];
  connectionStatus: DeviceConnectionStatusAPIResponse;
}

const initialState: ComponentStructureState = {
  structure: [],
  connectionStatus: [],
};

export const componentStructureSlice = createSlice({
  name: "component-structure-slice",
  initialState,
  reducers: {
    setComponentStructure(
      state,
      action: PayloadAction<(DeviceComponent | GroupComponent)[]>
    ) {
      state.structure = deepCopy(action.payload);
    },
    setConnectionStatus(
      state,
      action: PayloadAction<DeviceConnectionStatusAPIResponse>
    ) {
      state.connectionStatus = action.payload;
    },
  },
});

export const { setComponentStructure, setConnectionStatus } =
  componentStructureSlice.actions;
export default componentStructureSlice.reducer;
