import React, { useState, useMemo } from "react";
import {
  getComponentsList,
  prepareComponentStructure,
} from "../../../../utils/componentUtils";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { createGroup } from "../../../../services/ldmServiceAPI";
import { setComponentStructureForFlow } from "../../../../features/topologySlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { setComponentStructure } from "../../../../features/componentStructureSlice";
import { useNavigate } from "react-router-dom";

interface FormData {
  name: string;
  parent_id: number;
}

interface Props {
  setIsGroupModalOpen: (state: boolean) => void;
}

const AddGroupForm = (props: Props) => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    parent_id: 0,
  });

  const componentStructure = useSelector(
    (state: RootState) => state.componentStructure.structure
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectOptions: { [key: string]: Array<{ value: any; label: any }> } =
    useMemo(() => {
      const components = getComponentsList();
      return {
        parent_id: components
          .filter((c) => c.type === "group")
          .map((c) => ({ value: c.id, label: c.name ?? c.id })),
      };
    }, [componentStructure]); // eslint-disable-line

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      await createGroup(formData.name, formData.parent_id);
      await updateFlow();
      props.setIsGroupModalOpen(false);
      navigate(0); // refresh the page
    } catch (error) {
      console.log("Error: ", error);
    }
  }

  async function updateFlow() {
    prepareComponentStructure()
      .then((componentStructure) => {
        dispatch(setComponentStructure(componentStructure));
        dispatch(setComponentStructureForFlow(componentStructure));
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  }

  function editLabel(label: string) {
    return (label[0].toUpperCase() + label.slice(1)).replaceAll("_", " ");
  }

  return (
    <form onSubmit={handleSubmit} className="grid gap-6 gap-x-3 lg:w-80">
      {Object.entries(formData).map(([key, value]) => {
        switch (key) {
          case "name":
            return (
              <TextField
                key={key}
                label={editLabel(key)}
                type="text"
                required
                size="small"
                defaultValue={value}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
              />
            );
          case "parent_id":
            return (
              <FormControl key={key} size="small" required>
                <InputLabel>{editLabel(key)}</InputLabel>
                <Select
                  multiple={false}
                  value={value as any}
                  label={key}
                  sx={{ bgcolor: "white" }}
                  size="small"
                  onChange={(e) =>
                    setFormData({ ...formData, [key]: e.target.value })
                  }
                >
                  {selectOptions[key].map((o) => (
                    <MenuItem key={o.value} value={o.value}>
                      {o.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          default:
            return null;
        }
      })}
      <Button type="submit" variant="contained" color="primary">
        Add Group
      </Button>
    </form>
  );
};

export default AddGroupForm;
