import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  setTimeRangeOption,
  timeRangeOptions,
} from "../../features/timeRangeSlice";
import dayjs from "dayjs";

interface Props {
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * This component will be displayed on wider devices such that tablets, laptops
 * instead of "TimeRangeIconMenu" component.
 */
const TimeRangeSelect = (props: Props) => {
  const dispatch = useDispatch();
  const { timeRangeOptionId, startTime, endTime } = useSelector(
    (state: RootState) => state.timeRange
  );

  return (
    <FormControl>
      <InputLabel>Time Range</InputLabel>
      <Select
        className="min-w-[11rem]"
        sx={{
          display: { xs: "none", sm: "inherit" },
          "& .MuiSelect-select:focus": { backgroundColor: "transparent" },
        }}
        variant="outlined"
        size="small"
        value={timeRangeOptionId}
        renderValue={(optionId) => {
          if (optionId === 0) {
            const format = "D MMM YYYY HH:mm";
            const stFormat = dayjs.unix(startTime).format(format);
            const etFormat = dayjs.unix(endTime).format(format);
            return `${stFormat} — ${etFormat}`;
          }
          // else
          return timeRangeOptions.find((t) => t.id === timeRangeOptionId)!.name;
        }}
        onChange={(e) => dispatch(setTimeRangeOption(e.target.value as number))}
        label="Time Range"
      >
        {timeRangeOptions.map((option) => (
          <MenuItem
            value={option.id}
            key={option.id}
            onClick={() => option.id === 0 && props.setDialogOpen(true)}
          >
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TimeRangeSelect;
