import { similarColors, theme } from "../styles/theme";

export const commonChartOptions = {
  title: {
    padding: [3, 0],
    textStyle: {
      fontWeight: "normal",
      fontSize: 16,
      color: "#000",
    },
  },
  noDataTitle: {
    id: "noData",
    show: true,
    textStyle: {
      color: "grey",
      fontSize: 20,
    },
    text: "No Data",
    left: "center",
    top: "center",
  },
  loadSpinner: {
    color: theme.palette.primary.main,
    textColor: theme.palette.text.primary,
    fontSize: "1rem",
    spinnerRadius: 8,
    lineWidth: 4,
  },
  textStyle: {
    fontFamily: "Roboto",
  },
  color: similarColors,
  legend: {
    top: 25,
  },
  toolbox: {
    padding: [0, 0],
    emphasis: {
      iconStyle: {
        borderColor: theme.palette.primary.light,
      },
    },
  },
};
