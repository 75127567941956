import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeviceComponent, GroupComponent } from "../types/componentTypes";
import { deepCopy } from "../utils/otherUtils";

export interface flowStateType {
  componentStructureForFlow: (DeviceComponent | GroupComponent)[];
  detachedNodeIds: string[];
}

const initialState: flowStateType = {
  componentStructureForFlow: [],
  detachedNodeIds: [],
};

export const topologySlice = createSlice({
  name: "flow-slice",
  initialState,
  reducers: {
    setComponentStructureForFlow: (
      state,
      action: PayloadAction<(DeviceComponent | GroupComponent)[]>
    ) => {
      state.componentStructureForFlow = deepCopy(action.payload);
    },
    addDetachedNodeId: (state, action: PayloadAction<string>) => {
      if (state.detachedNodeIds.includes(action.payload))
        console.warn(
          "This node has already added to the detached nodes list: " +
            action.payload
        );
      else state.detachedNodeIds.push(action.payload);
    },
    removeDetachedNodeId: (state, action: PayloadAction<string>) => {
      const removeIndex = state.detachedNodeIds.indexOf(action.payload);
      if (removeIndex === -1)
        console.warn(
          "Detached node could not removed. Such detached node is not exist: " +
            action.payload
        );
      else state.detachedNodeIds.splice(removeIndex, 1);
    },
    clearDetachedNodeIds: (state) => {
      state.detachedNodeIds = [];
    },
  },
});

export const {
  setComponentStructureForFlow,
  addDetachedNodeId,
  removeDetachedNodeId,
  clearDetachedNodeIds,
} = topologySlice.actions;
export default topologySlice.reducer;
