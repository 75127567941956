import { Edge, Node } from "reactflow";
import { DeviceComponent, GroupComponent } from "../types/componentTypes";
import { store } from "../store";
import Dagre from "@dagrejs/dagre";
import { deepObjectEqual } from "./otherUtils";

const defaultGroupWidth = 360;
const defaultGroupHeight = 150;
const defaultDeviceWidth = 70;
const defaultDeviceHeight = defaultDeviceWidth;

/**
 * Checks whether nodeA is inside of nodeB or not.
 * @param inner the inner node
 * @param outer the outer node
 * @returns true if "inner" is inside of "outer", false otherwise.
 */
export function isInside(inner: Node, outer: Node): boolean {
  return (
    inner.positionAbsolute!.x > outer.positionAbsolute!.x &&
    inner.positionAbsolute!.x + inner.width! <
      outer.positionAbsolute!.x + outer.width! &&
    inner.positionAbsolute!.y > outer.positionAbsolute!.y &&
    inner.positionAbsolute!.y + inner.height! <
      outer.positionAbsolute!.y + outer.height!
  );
}

/**
 * Sets the "childNode" as a child node of the "parentNode".
 * @param childNode child node
 * @param parentNode parent node
 */
export function setChildNode(childNode: Node, parentNode: Node) {
  childNode.parentNode = parentNode.id;
  childNode.extent = "parent";
  childNode.position = {
    x: childNode.positionAbsolute!.x - parentNode.positionAbsolute!.x,
    y: childNode.positionAbsolute!.y - parentNode.positionAbsolute!.y,
  };
  childNode.selected = false;
}

/**
 * This method reads the current ComponentStructure global state. It produces
 * ReactFlow Node and Edge objects from that global state and returns them as
 * arrays in an object.
 */
export function prepareNodesAndEdges(
  componentStructure = store.getState().componentStructure.structure
) {
  let preparedNodes: Node[] = [];
  let preparedEdges: Edge[] = [];

  const selectedCompId = store.getState().component.id!;
  prepareRecursively(componentStructure); // fills initial nodes and edges arrays
  return { preparedNodes, preparedEdges };

  function prepareRecursively(
    components: (DeviceComponent | GroupComponent)[]
  ) {
    for (const component of components) {
      const isGroup = component.type === "group";
      const newNode: Node = {
        id: String(component.id),
        data: { label: component.name ?? component.id },
        selected: selectedCompId === component.id,
        position: {
          x: 0,
          y: 0,
        },
        get positionAbsolute() {
          return this.position;
        },
        parentNode: component.parentGroupId?.toString(),
        extent: component.parentGroupId ? "parent" : undefined,
        ...(isGroup
          ? {
              type: "resizableGroup",
              style: {
                width: defaultGroupWidth,
                height: defaultGroupHeight,
              },
              width: defaultGroupWidth,
              height: defaultGroupHeight,
            }
          : {
              type: "device",
              style: {
                width: defaultDeviceWidth,
                height: defaultDeviceHeight,
              },
              width: defaultDeviceWidth,
              height: defaultDeviceHeight,
            }),
      };
      preparedNodes.push(newNode);
      if (component.type === "group") prepareRecursively(component.childs);
      else if (component.preMeterId) {
        const sourceId = component.preMeterId.toString();
        const targetId = component.id.toString();
        const newEdge: Edge = {
          id: "e" + sourceId + "-" + targetId,
          source: sourceId,
          target: targetId,
          type: "buttonEdge",
          zIndex: 1001,
        };
        preparedEdges.push(newEdge);
      }
    }
  }
}

export function autoLayoutNodes(nodes: Node[], edges: Edge[]) {
  const rootGroupNodes = getRootGroupNodes(nodes);
  const tempNode: Node = {
    id: "tempNode",
    data: "tempNode",
    position: { x: 0, y: 0 },
    type: "group",
    style: {
      width: defaultGroupWidth,
      height: defaultGroupHeight,
    },
    width: defaultGroupWidth,
    height: defaultGroupHeight,
  };
  for (const rootGroupNode of rootGroupNodes) {
    rootGroupNode.parentNode = "tempNode";
  }
  let allLayoutedNodes: Node[] = [];
  layoutRecursively(tempNode); // fills the allLayoutedNodes array
  for (const layoutedNode of allLayoutedNodes) {
    if (rootGroupNodes.map((rgn) => rgn.id).includes(layoutedNode.id))
      layoutedNode.parentNode = undefined;
  }
  return allLayoutedNodes.slice(1);

  function layoutRecursively(groupNode: Node) {
    const childNodes = getChildNodes(groupNode.id, nodes);
    if (childNodes.length === 0) {
      allLayoutedNodes = [groupNode].concat(allLayoutedNodes);
      return;
    }
    const childGroupNodes = childNodes.filter(
      (node) => node.type === "resizableGroup"
    );
    if (childGroupNodes.length > 0) {
      for (const childGroupNode of childGroupNodes) {
        layoutRecursively(childGroupNode);
      }
    }
    const edgesInGroup = getEdgesBetweenNodes(childNodes, edges);
    const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
    g.setGraph({
      rankdir: "TB",
      align: "UL",
      nodesep: 25,
      marginy: 50,
      marginx: 50,
      ranksep: 100,
    });
    // @ts-expect-error
    childNodes.forEach((node) => g.setNode(node.id, node));
    edgesInGroup.forEach((edge) => g.setEdge(edge.source, edge.target));
    Dagre.layout(g);

    const layoutedChildNodes = childNodes.map((node) => {
      const { x, y, width, height } = g.node(node.id);
      g.node(node.id).padding = 0;
      return {
        ...node,
        position: { x: x - width / 2, y: y - height / 2 },
      };
    });
    groupNode.style!.height = g.graph().height;
    groupNode.height = g.graph().height;
    groupNode.style!.width = g.graph().width;
    groupNode.width = g.graph().width;

    // yorum koy
    layoutedChildNodes.forEach((n1) => {
      const index = allLayoutedNodes.findIndex((n2) => n2.id === n1.id);
      if (index === -1) allLayoutedNodes.push(n1);
      else allLayoutedNodes[index] = n1;
    });
    allLayoutedNodes = [groupNode].concat(allLayoutedNodes);
  }
}

function getChildNodes(parentNodeId: String, searchNodes: Node[]) {
  return searchNodes.filter((node) => node.parentNode === parentNodeId);
}

function getEdgesBetweenNodes(nodes: Node[], searchEdges: Edge[]) {
  const nodeIds = nodes.map((node) => node.id);
  return searchEdges.filter(
    (edge) => nodeIds.includes(edge.source) && nodeIds.includes(edge.target)
  );
}

function getRootGroupNodes(searchNodes: Node[]): Node[] {
  const discoveredNodeIds: String[] = [];
  const rootNodeIds: Node[] = [];
  for (const node of searchNodes) {
    if (!node.parentNode || !discoveredNodeIds.includes(node.parentNode)) {
      rootNodeIds.push(node);
    }
    discoveredNodeIds.push(node.id);
  }
  return rootNodeIds;
}

export function isRfInstanceValid(
  rfInstance: any,
  checkNodes: Node[],
  checkEdges: Edge[]
): Boolean {
  if (!rfInstance || !rfInstance.nodes || !rfInstance.edges) return false;

  // Check nodes
  if (checkNodes.length !== rfInstance.nodes.length) return false;
  const checkNodeIds = new Set(checkNodes.map((n) => n.id));
  for (const node of rfInstance.nodes as Node[]) {
    if (!checkNodeIds.delete(node.id)) return false;
  }
  // Check edges
  if (checkEdges.length !== rfInstance.edges.length) return false;
  checkEdges.sort((a, b) => (a.id >= b.id ? 1 : -1));
  rfInstance.edges.sort((a: Edge, b: Edge) => (a.id >= b.id ? 1 : -1));
  for (let index = 0; index < checkEdges.length; index++) {
    if (!deepObjectEqual(checkEdges[index], rfInstance.edges[index]))
      return false;
  }

  return true;
}
