import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridColumnGroupingModel,
  GridCsvExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { prepareReportsTableRows } from "../../utils/chartUtils";
import {
  getShortMeasurement,
  getVirtualMeasurement,
} from "../../services/analyticsServiceAPI";
import { getComponentById } from "../../utils/componentUtils";
import { setMeasurementsData } from "../../features/measurementsSlice";
import { PdfExportMenuItem } from "./PdfExportMenuItem";
import dayjs from "dayjs";
import { PageHeader } from "../../components/PageHeader";
import ComponentTreeSelect from "../../components/ComponentTreeSelect";
import TimeRange from "../../components/TimeRange/TimeRange";
import AutoUpdateButton from "../../components/AutoUpdateButton";
import { Interval } from "../../types/analyticsResponseTypes";

const INTERVAL: Interval = "h1";

const columns: GridColDef[] = [
  {
    field: "time",
    headerName: "Time",
    type: "number",
    valueFormatter: ({ value }) => dayjs.unix(value).format("L LT"),
    align: "center",
    headerAlign: "center",
    flex: 14,
    minWidth: 140,
  },
  {
    field: "t0active",
    headerName: "T0",
    type: "number",
    flex: 7,
    minWidth: 70,
  },
  {
    field: "t1active",
    headerName: "T1",
    type: "number",
    flex: 7,
    minWidth: 70,
  },
  {
    field: "t2active",
    headerName: "T2",
    type: "number",
    flex: 7,
    minWidth: 70,
  },
  {
    field: "t3active",
    headerName: "T3",
    type: "number",
    flex: 7,
    minWidth: 70,
  },
  {
    field: "peakDemand",
    headerName: "Peak",
    type: "number",
    flex: 10,
    minWidth: 100,
  },
  {
    field: "offPeakDemand",
    headerName: "Off-Peak",
    type: "number",
    flex: 10,
    minWidth: 100,
  },
  {
    field: "t0reactive",
    headerName: "T0",
    type: "number",
    flex: 7,
    minWidth: 70,
  },
  {
    field: "t1reactive",
    headerName: "T1",
    type: "number",
    flex: 7,
    minWidth: 70,
  },
  {
    field: "t2reactive",
    headerName: "T2",
    type: "number",
    flex: 7,
    minWidth: 70,
  },
  {
    field: "t3reactive",
    headerName: "T3",
    type: "number",
    flex: 7,
    minWidth: 70,
  },
  {
    field: "t0appearent",
    headerName: "T0",
    type: "number",
    flex: 7,
    minWidth: 70,
  },
  {
    field: "t1appearent",
    headerName: "T1",
    type: "number",
    flex: 7,
    minWidth: 70,
  },
  {
    field: "t2appearent",
    headerName: "T2",
    type: "number",
    flex: 7,
    minWidth: 70,
  },
  {
    field: "t3appearent",
    headerName: "T3",
    type: "number",
    flex: 7,
    minWidth: 70,
  },
];

const columnGroups: GridColumnGroupingModel = [
  {
    groupId: "Active (kWh)",
    children: [
      { field: "t0active" },
      { field: "t1active" },
      { field: "t2active" },
      { field: "t3active" },
    ],
    headerAlign: "center",
  },
  {
    groupId: "Demand (kW)",
    children: [{ field: "peakDemand" }, { field: "offPeakDemand" }],
    headerAlign: "center",
  },
  {
    groupId: "Reactive (kWh)",
    children: [
      { field: "t0reactive" },
      { field: "t1reactive" },
      { field: "t2reactive" },
      { field: "t3reactive" },
    ],
    headerAlign: "center",
  },
  {
    groupId: "Appearent (kWh)",
    children: [
      { field: "t0appearent" },
      { field: "t1appearent" },
      { field: "t2appearent" },
      { field: "t3appearent" },
    ],
    headerAlign: "center",
  },
];

type PuantRange = { start: [number, number]; end: [number, number] };
const T1: PuantRange = { start: [6, 0], end: [17, 0] };
const T2: PuantRange = { start: T1.end, end: [22, 0] };
const T3: PuantRange = { start: T2.end, end: T1.start };

export const Reports = () => {
  const dispatch = useDispatch();
  const measurements = useSelector(
    (state: RootState) => state.measurements.data
  );
  const { startTime, endTime } = useSelector(
    (state: RootState) => state.timeRange
  );
  const componentId = useSelector((state: RootState) => state.component.id!);
  const [isLoading, setIsLoading] = useState(true);
  const [window, setWindow] = useState<"hourly" | "daily" | "weekly">("hourly");
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    setIsLoading(true);
    let mounted = true;
    let controller = new AbortController();
    // Get Measurements
    (getComponentById(componentId)!.type === "group"
      ? getVirtualMeasurement(
          startTime,
          endTime,
          componentId,
          INTERVAL,
          undefined,
          controller.signal
        )
      : getShortMeasurement(
          startTime,
          endTime,
          componentId,
          INTERVAL,
          undefined,
          controller.signal
        )
    )
      .then((measurements) => {
        if (mounted) {
          setRows(
            prepareReportsTableRows(measurements, window, {
              t1: T1,
              t2: T2,
              t3: T3,
            })
          );
          dispatch(setMeasurementsData(measurements));
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (mounted) {
          console.log(error);
          setIsLoading(false);
        }
      });
    return () => {
      mounted = false;
      controller.abort();
    };
  }, [startTime, endTime, componentId]); // eslint-disable-line

  const onIntervalChange = (e: SelectChangeEvent) => {
    let newInterval = e.target.value as typeof window;
    setRows(
      prepareReportsTableRows(measurements, newInterval, {
        t1: T1,
        t2: T2,
        t3: T3,
      })
    );
    setWindow(newInterval);
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <FormControl sx={{ mr: "1vw" }}>
        <InputLabel>Interval</InputLabel>
        <Select
          value={window}
          label="Interval"
          onChange={onIntervalChange}
          size="small"
          variant="outlined"
        >
          <MenuItem value={"hourly"}>Hourly</MenuItem>
          <MenuItem value={"daily"}>Daily</MenuItem>
          <MenuItem value={"weekly"}>Weekly</MenuItem>
        </Select>
      </FormControl>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem />
        <PdfExportMenuItem
          rows={rows}
          columns={columns}
          columnGroups={columnGroups}
          componentId={componentId}
          endTime={endTime}
          startTime={startTime}
        />
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );

  return (
    <div className="grid">
      <PageHeader
        title="Reports"
        element={
          <div className="flex gap-2">
            <ComponentTreeSelect />
            <TimeRange />
            <AutoUpdateButton />
          </div>
        }
      />
      <div className="card p-0 overflow-hidden">
        <DataGrid
          sx={{ p: 2, pb: 0, bgcolor: "background.default" }}
          rows={rows}
          columns={columns}
          disableRowSelectionOnClick
          slots={{ toolbar: CustomToolbar }}
          loading={isLoading}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          experimentalFeatures={{ columnGrouping: true }}
          columnGroupingModel={columnGroups}
          disableColumnMenu
          showCellVerticalBorder={true}
          showColumnVerticalBorder={true}
          columnHeaderHeight={45}
        />
      </div>
    </div>
  );
};
