import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";

interface Props {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  title: string;
  children: JSX.Element;
}

const CustomModal = (props: Props) => {
  if (!props.isOpen) return null;
  return (
    <Dialog open={props.isOpen} onClose={() => props.setIsOpen(false)}>
      <DialogTitle
        display="flex"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        {props.title}
        <IconButton
          sx={{ alignSelf: "start" }}
          onClick={() => props.setIsOpen(false)}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>{props.children}</DialogContent>
    </Dialog>
  );
};

export default CustomModal;
