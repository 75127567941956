import {
  DataGrid,
  GridCell,
  GridCellProps,
  GridColDef,
  GridColumnGroupingModel,
  GridCsvExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  useGridApiContext,
} from "@mui/x-data-grid";
import ComponentTreeSelect from "../components/ComponentTreeSelect";
import { PageHeader } from "../components/PageHeader";
import TimeRange from "../components/TimeRange/TimeRange";
import { useEffect, useState } from "react";
import { RootState } from "../store";
import { useDispatch, useSelector } from "react-redux";
import {
  getComponentById,
  getComponentsList,
  getLabel,
} from "../utils/componentUtils";
import { getMeasurement } from "../services/analyticsServiceAPI";
import { setMeasurementsData } from "../features/measurementsSlice";
import { prepareDetailsReportRows } from "../utils/chartUtils";
import dayjs from "dayjs";
import { setComponentID } from "../features/componentSlice";
import measCfg from "../assets/config/measurementConfigs.json";

const columnNames = [
  "calc_time",
  "interval",
  "frequency",
  "line",
  "active_power",
  "reactive_power",
  "apparent_power",
  "window_active_energy",
  "window_reactive_energy",
  "window_apparent_energy",
  "cumulative_active_energy",
  "cumulative_reactive_energy",
  "cumulative_apparent_energy",
  "fi_angle",
  "i_rms",
  "v_rms",
  "thdi",
  "thdv",
];

const harmonicMeasNames = {
  v_h_even: Array<string>(),
  v_h_odd: Array<string>(),
  i_h_even: Array<string>(),
  i_h_odd: Array<string>(),
};

let hIdx = 0;
while (hIdx < 25) {
  harmonicMeasNames["v_h_even"].push("v_h" + (hIdx * 2 + 2));
  harmonicMeasNames["v_h_odd"].push("v_h" + (hIdx * 2 + 1));
  harmonicMeasNames["i_h_even"].push("i_h" + (hIdx * 2 + 2));
  harmonicMeasNames["i_h_odd"].push("i_h" + (hIdx * 2 + 1));
  hIdx++;
}

Object.values(harmonicMeasNames).forEach((harmonicList) => {
  columnNames.push(...harmonicList);
});

const columnGroups: GridColumnGroupingModel = Object.keys(
  harmonicMeasNames
).map((measName) => {
  return {
    groupId: measName + ` (${(measCfg as any)[measName].unit})`,
    children: harmonicMeasNames[measName as "v_h_even"].map((harName) => {
      return { field: harName };
    }),
    headerAlign: "center",
  };
});

const DetailsReport = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState({ state: false, message: "" });
  const componentId = useSelector((state: RootState) => state.component.id!);
  const { startTime, endTime } = useSelector(
    (state: RootState) => state.timeRange
  );

  const [rows, setRows] = useState<any[]>([]);

  const columns: GridColDef[] = columnNames.map((colName) => {
    const headerName =
      colName +
      (colName in measCfg ? ` (${(measCfg as any)[colName].unit})` : "");
    return {
      field: colName,
      headerName: headerName,
      headerAlign: "center",
      align: ["interval", "calc_time", "line"].includes(colName)
        ? "center"
        : "right",
      width: headerName.length * 7.4 + (colName === "calc_time" ? 75 : 20),
      minWidth: 65,
      valueFormatter: ({ value }) => {
        if (colName === "calc_time") return dayjs.unix(value).format("L LT");
        if (colName.includes("_h")) {
          let prefix = colName.slice(0, 3); // v_h or i_h
          let suffix = +colName.slice(4) % 2 === 0 ? "_even" : "_odd";
          return (value / (measCfg as any)[prefix + suffix].divide).toFixed(2);
        } else if (typeof value === "number")
          return (value / (measCfg as any)[colName].divide).toFixed(2);
        else return value;
      },
    };
  });

  useEffect(() => {
    setIsLoading(true);
    let mounted = true;
    let controller = new AbortController();

    if (getComponentById(componentId)!.type !== "group") fetch();
    else {
      const firstFoundDevice = getComponentsList().find(
        (c) => c.type !== "group"
      );
      if (!firstFoundDevice)
        setIsError({
          state: true,
          message: "There is not exist any device component!",
        });
      else dispatch(setComponentID(firstFoundDevice.id));
    }

    return () => {
      mounted = false;
      controller.abort();
    };

    async function fetch() {
      try {
        const measurements = await getMeasurement(
          startTime,
          endTime,
          componentId,
          undefined,
          undefined,
          controller.signal
        );
        if (mounted) {
          dispatch(setMeasurementsData(measurements));
          setRows(
            measurements.length === 0
              ? []
              : prepareDetailsReportRows(measurements)
          );
          setIsLoading(false);
        }
      } catch (error) {
        if (mounted) {
          console.log(error);
          setIsLoading(false);
        }
      }
    }
  }, [startTime, endTime, componentId]); // eslint-disable-line

  /* This custom cell allows us to use rowspan feature. */
  const MyCell = (props: GridCellProps) => {
    let style = {
      minWidth: props.width,
      maxWidth: props.width,
      minHeight: props.height,
      maxHeight: props.height === "auto" ? "none" : props.height,
      ...props.style,
    };
    const apiRef = useGridApiContext();
    const row = apiRef.current.getRow(props.rowId);
    if (row && row.rowSpan && row.rowSpan[props.column.field]) {
      const span = row.rowSpan[props.column.field];
      style = {
        ...style,
        minHeight: (props.height as number) * span,
        maxHeight: (props.height as number) * span,
        backgroundColor: "#fff",
        zIndex: 1,
        borderBottom: "rgba(224,224,224,1) 1px solid",
      };
    }
    return <GridCell {...props} style={style} />;
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem
          options={{ fileName: `eprobe_details_${getLabel(componentId)}` }}
        />
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );

  return (
    <div className="grid">
      <PageHeader
        title="Details Report"
        element={
          <div className="flex gap-2">
            <ComponentTreeSelect optionsType="device" />
            <TimeRange />
          </div>
        }
      />
      {isError.state ? (
        isError.message
      ) : (
        <div className="card p-0 overflow-hidden h-[638px]">
          <DataGrid
            sx={{ p: 2, pb: 0, bgcolor: "background.default" }}
            rows={rows}
            columns={columns}
            disableRowSelectionOnClick
            slots={{
              cell: MyCell,
              toolbar: CustomToolbar,
            }}
            loading={isLoading}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 15,
                },
              },
            }}
            experimentalFeatures={{ columnGrouping: true }}
            columnGroupingModel={columnGroups}
            disableColumnMenu
            showCellVerticalBorder={true}
            showColumnVerticalBorder={true}
            columnHeaderHeight={30}
            rowHeight={30}
          />
        </div>
      )}
    </div>
  );
};

export default DetailsReport;
