import { createRef, useEffect, useRef, useState } from "react";
import { MeasurementsAPIResponse } from "../../types/analyticsResponseTypes";
import DetailsChart from "../../components/charts/DetailsChart";
import EChartsReact from "echarts-for-react";
import { commonChartOptions } from "../../assets/config/commonChartOptions";
import { prepareDetailsChartsData } from "../../utils/chartUtils";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const singleLineMeasurements = ["frequency"];
const threeLineMeasurements = [
  "active_power",
  "apparent_power",
  "cumulative_active_energy",
  "cumulative_apparent_energy",
  "cumulative_reactive_energy",
  "fi_angle",
  "i_rms",
  "reactive_power",
  "thdi",
  "thdv",
  "v_rms",
  "window_active_energy",
  "window_apparent_energy",
  "window_reactive_energy",
];
const harmonicMeasurements = ["i_h_odd", "i_h_even", "v_h_odd", "v_h_even"]; // except harmonics!
const harmonicList = {
  even: Array<string>(),
  odd: Array<string>(),
};
for (let i = 0; i < 25; i++) {
  harmonicList.even.push(`h${i * 2 + 2}`);
  harmonicList.odd.push(`h${i * 2 + 1}`);
}

interface IProps {
  measurements: MeasurementsAPIResponse;
  isLoading: boolean;
}

const DetailsChartCards = (props: IProps) => {
  const chartRefs = useRef(
    [
      ...singleLineMeasurements,
      ...threeLineMeasurements,
      ...harmonicMeasurements,
    ].map(() => createRef<EChartsReact>())
  );

  const chartSeries = useRef<any>(null);

  const [selectedHarmonics, setSelectedHarmonics] = useState<any>({
    v_h_even: "h2",
    v_h_odd: "h1",
    i_h_even: "h2",
    i_h_odd: "h1",
  });

  useEffect(() => {
    if (props.isLoading) {
      chartRefs.current.forEach((ref) => {
        const chartInstance = ref.current!.getEchartsInstance();
        chartInstance.showLoading(commonChartOptions.loadSpinner);
      });
      return;
    } else {
      chartSeries.current = prepareDetailsChartsData(props.measurements);
      [
        ...singleLineMeasurements,
        ...threeLineMeasurements,
        ...harmonicMeasurements,
      ].forEach((name, idx) => {
        const chartInstance =
          chartRefs.current[idx].current!.getEchartsInstance();
        let series = {};
        if (chartSeries.current?.[name])
          series = singleLineMeasurements.includes(name)
            ? [{ name: name, data: chartSeries.current[name], type: "line" }]
            : threeLineMeasurements.includes(name)
            ? Object.keys(chartSeries.current[name]).map((line) => ({
                name: line,
                data: chartSeries.current[name][line],
                type: "line",
              }))
            : Object.keys(
                chartSeries.current[name][selectedHarmonics[name]]
              ).map((line) => ({
                name: line,
                data: chartSeries.current[name][selectedHarmonics[name]][line],
                type: "line",
              }));
        const isNoData = Object.values(series).every(
          (ser: any) => !ser?.data?.length
        );
        chartInstance.setOption(
          {
            title: { id: "noData", show: isNoData },
            legend: { show: !isNoData },
            series: series,
          },
          { replaceMerge: ["series"] }
        );
        chartInstance.hideLoading();
      });
    }
  }, [props.isLoading, selectedHarmonics]); // eslint-disable-line

  return (
    <div className="flex flex-col gap-1 w-full">
      {[
        ...singleLineMeasurements,
        ...threeLineMeasurements,
        ...harmonicMeasurements,
      ].map((name, idx) => (
        <div
          key={name}
          className="card h-72 sm:h-[30rem] p-4 sm:p-8 flex flex-col gap-4"
        >
          <div className="flex-1">
            <DetailsChart title={name} chartRef={chartRefs.current[idx]} />
          </div>

          {harmonicMeasurements.includes(name) && (
            <FormControl sx={{ alignSelf: "center" }}>
              <InputLabel>Harmonic</InputLabel>
              <Select
                // @ts-ignore
                value={selectedHarmonics[name]}
                onChange={(e) =>
                  // @ts-ignore
                  setSelectedHarmonics((oldState) => ({
                    ...oldState,
                    [name]: e.target.value,
                  }))
                }
                sx={{ minWidth: "7rem" }}
                label="Harmonic"
                size="small"
              >
                {harmonicList[name.includes("even") ? "even" : "odd"].map(
                  (h) => (
                    <MenuItem key={h} value={h} children={h} />
                  )
                )}
              </Select>
            </FormControl>
          )}
        </div>
      ))}
    </div>
  );
};

export default DetailsChartCards;
