import { Snackbar, Alert, AlertTitle } from "@mui/material";

interface Props {
  variant: "success" | "error" | "info" | "warning";
  message: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Toast = (props: Props) => {
  function handleClose(event?: React.SyntheticEvent | Event, reason?: string) {
    if (reason === "clickaway") return;
    props.setOpen(false);
  }

  return (
    <Snackbar
      open={props.open}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <Alert onClose={handleClose} severity={props.variant}>
        <AlertTitle>
          {props.variant.charAt(0).toUpperCase() + props.variant.slice(1)}
        </AlertTitle>
        {props.message}
      </Alert>
    </Snackbar>
  );
};
