import ReactECharts from "echarts-for-react";
import { memo } from "react";
import { commonChartOptions } from "../../assets/config/commonChartOptions";

interface Props {
  chartRef: React.RefObject<ReactECharts>;
}

const DynamicHeatmapChart = (props: Props) => {
  const option = {
    title: [commonChartOptions.noDataTitle],
    tooltip: {
      valueFormatter: (value: number) => value + " kWh",
    },
    textStyle: commonChartOptions.textStyle,
    animation: false,
    xAxis: {
      type: "category",
      axisLabel: {
        hideOverlap: true,
      },
      splitArea: {
        show: true,
      },
      data: [],
    },
    yAxis: {
      type: "category",
      splitArea: {
        show: true,
      },
      axisLabel: {
        interval: 0,
      },
      data: [],
    },
    visualMap: {
      min: 0,
      max: 1000000,
      orient: "horizontal",
      bottom: 0,
      left: 0,
      calculable: true,
    },
    grid: {
      bottom: 75,
      left: 111,
      right: 0,
      top: 25,
    },
    series: [
      {
        name: "Active Energy",
        type: "heatmap",
        data: [],
      },
    ],
  };

  return (
    <ReactECharts
      style={{ width: "100%", height: "100%" }}
      ref={props.chartRef}
      option={option}
    />
  );
};

export default memo(DynamicHeatmapChart);
