import { configureStore } from "@reduxjs/toolkit";
import autoUpdateReducer from "./features/autoUpdateSlice";
import circuitEnergyConsumptionReducer from "./features/circuitEnergyConsumptionSlice";
import measurementsReducer from "./features/measurementsSlice";
import navBarReducer from "./features/navBarSlice";
import timeRangeReducer from "./features/timeRangeSlice";
import componentReducer from "./features/componentSlice";
import componentStructureReducer from "./features/componentStructureSlice";
import topologyReducer from "./features/topologySlice";

export const store = configureStore({
  reducer: {
    navBar: navBarReducer,
    timeRange: timeRangeReducer,
    autoUpdate: autoUpdateReducer,
    component: componentReducer,
    circuitEnergyConsumption: circuitEnergyConsumptionReducer,
    measurements: measurementsReducer,
    componentStructure: componentStructureReducer,
    topology: topologyReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
